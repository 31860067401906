import { useFeatureFlags } from '@cofenster/web-components';
import type { MaybeSubtitlesLanguage } from '../../../api/hooks/renderDescription/types';
import { useProjectRenderDescription } from '../../../contexts/projectRenderDescription/useProjectRenderDescription';
import { useUser } from '../../../contexts/user/useUser';
import { useIsTooLongToExport } from '../../../hooks/project/useIsTooLongToExport';
import { useStartSafeRender } from '../../../hooks/project/useStartSafeRender';

const formatDuration = (duration: number) => `${Math.floor(duration / 60)}m${Math.floor(duration % 60)}s`;

export const useStartRenderButtonProps = (
  projectId: string,
  subtitlesLanguage: MaybeSubtitlesLanguage,
  { withNavigation }: { withNavigation: boolean } = { withNavigation: false }
) => {
  const { user } = useUser();
  const { startSafeRender, loading: hasRequestedRender } = useStartSafeRender(projectId, subtitlesLanguage, {
    withNavigation,
  });
  const { projectRenderDescription } = useProjectRenderDescription();
  const {
    isTooLongToExport,
    duration,
    max,
    type: limitType,
  } = useIsTooLongToExport(projectRenderDescription?.totalDurationInSeconds ?? 0);
  const withEmergencyDisableRenderFlag = useFeatureFlags().hasFeature('EMERGENCY_DISABLE_RENDER');

  if (isTooLongToExport) {
    return {
      disabled: true,
      label: `i18n.projectVideo.renderButton.tooLong.${limitType}`,
      i18nParams: {
        duration: formatDuration(duration),
        max: formatDuration(max),
      },
      'data-testid': 'render-button',
    };
  }

  if (withEmergencyDisableRenderFlag) {
    return {
      disabled: true,
      label: 'i18n.projectVideo.renderButton.emergency',
      'data-testid': 'render-button',
      onClick: user?.isImpersonated ? startSafeRender : undefined,
      loading: hasRequestedRender,
    };
  }

  return {
    loading: hasRequestedRender,
    onClick: startSafeRender,
  };
};
