import { useCallback, useMemo, useState } from 'react';

import { useSnackbars } from '@cofenster/web-components';

import { useCanRenderProject } from '../../api/hooks/project/useCanRenderProject';
import { useRenderProject } from '../../api/hooks/project/useRenderProject';
import type { MaybeSubtitlesLanguage } from '../../api/hooks/renderDescription/types';
import { useGotoProjectVideo } from '../navigation/useGotoProjectVideo';

export const useStartSafeRender = (
  projectId: string,
  subtitlesLanguage: MaybeSubtitlesLanguage,
  { withNavigation }: { withNavigation: boolean } = { withNavigation: false }
) => {
  const [requestingRendering, setRequestingRendering] = useState(false);
  const { openSnackbar } = useSnackbars();
  const { renderProject, loading } = useRenderProject(projectId, { subtitlesLanguage });
  const { refetch: getCanRenderProject } = useCanRenderProject(projectId);

  const startRender = useCallback(() => renderProject(), [renderProject]);
  const gotoProjectVideo = useGotoProjectVideo(projectId);

  const ensureReadyToRender = useCallback(async () => {
    const { data } = await getCanRenderProject();
    return !!data.canRenderProject;
  }, [getCanRenderProject]);

  const startSafeRender = useCallback(async () => {
    try {
      setRequestingRendering(true);
      if (await ensureReadyToRender()) {
        await startRender();
        if (withNavigation) gotoProjectVideo();
      } else openSnackbar({ children: 'i18n.common.exportVideo.error.general' });
    } finally {
      setRequestingRendering(false);
    }
  }, [ensureReadyToRender, openSnackbar, startRender, withNavigation, gotoProjectVideo]);

  return useMemo(
    () => ({ startSafeRender, loading: loading || requestingRendering }),
    [startSafeRender, loading, requestingRendering]
  );
};
