import {
  Button,
  DialogActions,
  DialogContent,
  Form,
  FormCheckbox,
  FormSelectableBoxInput,
  GridContainer,
  GridItem,
  Icon,
  Typography,
  VisuallyHidden,
} from '@cofenster/web-components';
import type { FC } from 'react';
import * as Yup from 'yup';
import { BlankFieldset } from './BlankFieldset';

const validationSchema = Yup.object().shape({
  destination: Yup.string().oneOf(['NEW_PROJECT', 'EXISTING_PROJECT']).required('i18n.form.error.generic.required'),
  keepSourceScenes: Yup.boolean().required('i18n.form.error.generic.required'),
});

export type PickDestinationValues = Yup.InferType<typeof validationSchema>;

const initialValues: PickDestinationValues = {
  destination: 'NEW_PROJECT',
  keepSourceScenes: false,
};

export const PickDestination: FC<{
  sceneCount: number;
  onCancel: () => unknown;
  onPick: (values: PickDestinationValues) => unknown;
}> = ({ sceneCount, onCancel, onPick }) => {
  return (
    <>
      <DialogContent>
        <Form
          id="move-scenes-to-project-destination-form"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => onPick(values)}
          preserveFormState="move-scenes-to-project-destination"
          validateOnChange
        >
          <GridContainer>
            <GridItem xs={12}>
              <BlankFieldset>
                <VisuallyHidden as="legend">i18n.dialogs.MoveScenesToProjectDialog.destination.legend</VisuallyHidden>
                <FormSelectableBoxInput
                  name="destination"
                  id="new-project-destination"
                  data-testid="new-project-destination"
                  type="radio"
                  value="NEW_PROJECT"
                  title="i18n.dialogs.MoveScenesToProjectDialog.destination.newProject"
                  mainIcon={<Icon type="PlusCircleIcon" />}
                />
                <FormSelectableBoxInput
                  name="destination"
                  id="existing-project-destination"
                  data-testid="existing-project-destination"
                  type="radio"
                  value="EXISTING_PROJECT"
                  title="i18n.dialogs.MoveScenesToProjectDialog.destination.existingProject"
                  mainIcon={<Icon type="MonitorPlayIcon" />}
                />
              </BlankFieldset>
            </GridItem>
            <GridItem xs={12}>
              <FormCheckbox
                id="keep-source-scenes"
                name="keepSourceScenes"
                data-testid="keep-source-scenes-switch"
                label={
                  <Typography variant="l" i18nParams={{ count: sceneCount }}>
                    i18n.dialogs.MoveScenesToProjectDialog.keepSourceScenes
                  </Typography>
                }
              />
            </GridItem>
          </GridContainer>
        </Form>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <Button type="button" variant="tertiary" onClick={onCancel}>
          i18n.global.cancel
        </Button>
        <Button
          type="submit"
          data-testid="pick-destination"
          form="move-scenes-to-project-destination-form"
          variant="primary"
        >
          i18n.global.continue
        </Button>
      </DialogActions>
    </>
  );
};
