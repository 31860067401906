import type { VideoFormat } from '@cofenster/constants';
import {
  EmptyState,
  GridContainer,
  GridItem,
  LoadingSpinner,
  ResultPagination,
  SearchResults,
  type SelectChangeEvent,
  Typography,
  useFeatureFlags,
  useGoto,
  useSnackbars,
} from '@cofenster/web-components';
import { type FC, useCallback, useState } from 'react';
import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolder';
import { useProjectTemplates } from '../../../api/hooks/projectTemplate/useProjectTemplates';
import { VideoFormatSelect, type VideoFormatSelectValue } from '../../../components/project/VideoFormatSelect';
import { useProjectTemplatesSearchFilter } from '../../../contexts/projectTemplateSearchFilter/ProjectTemplatesSearchFilterProvider';
import { useI18n } from '../../../i18n';
import { routes } from '../../../routes';
import { ProjectTemplateTile } from '../../ProjectTemplates/ProjectTemplateTile';
import { ProjectTemplatesContent } from '../../ProjectTemplates/ProjectTemplatesContent';
import { ProjectTemplatesSearchField } from '../../ProjectTemplates/ProjectTemplatesSearchField';
import { useProjectCreatedTracking } from '../useProjectCreatedTracking';
import { VideoFormatButton } from './VideoFormatButton';
import { VIDEO_FORMATS_FIELD_OPTIONS } from './options';
import { useCreateBasedOnMode } from './useCreateBasedOnMode';

export const NewProjectForm: FC<{
  projectFolder: ProjectFolder;
  create: { mode: 'create' } | { mode: 'move'; sceneIds: string[] } | { mode: 'copy'; sceneIds: string[] };
}> = ({ projectFolder, create }) => {
  const { filter, page, setPage, setFilter } = useProjectTemplatesSearchFilter();
  const { paginatedProjectTemplates, loading, error } = useProjectTemplates(filter);
  const goto = useGoto();
  const { translate } = useI18n();

  const onVideoFormatChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as VideoFormatSelectValue;
      setFilter('videoFormat', value === 'All' ? undefined : value);
    },
    [setFilter]
  );

  const [submittingValue, setSubmittingValue] = useState<string | null>(null);
  const [createProject] = useCreateBasedOnMode(create);
  const trackProjectCreated = useProjectCreatedTracking(projectFolder);
  const { openSnackbar } = useSnackbars();
  const { hasFeature } = useFeatureFlags();

  const onFormatClicked = useCallback(
    async (format: VideoFormat) => {
      setSubmittingValue(format);
      try {
        const name = translate('projectCreate.form.name.defaultValue');
        const result = await createProject({ name, projectFolderId: projectFolder.id, videoFormat: format });
        const projectId = result?.id;
        if (projectId) {
          trackProjectCreated(projectId, name, format);
          goto(routes.projectEdit, { projectId });
          openSnackbar({
            variant: 'success',
            children: 'i18n.snackbars.projectCreatedSnackbar.copy',
          });
        }
      } finally {
        setSubmittingValue(null);
      }
    },
    [createProject, projectFolder.id, goto, openSnackbar, translate, trackProjectCreated]
  );

  return (
    <GridContainer>
      <GridItem xs={12} mt={3}>
        <Typography variant="h3" component="h2">
          i18n.projectCreate.projectTemplates.blankProjectHeader
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer>
          {Object.entries(VIDEO_FORMATS_FIELD_OPTIONS).map(([format, configuration]) => (
            <GridItem xs={12} sm={3} key={format}>
              <VideoFormatButton
                format={format as VideoFormat}
                configuration={configuration}
                onClick={onFormatClicked}
                loading={submittingValue === format}
                disabled={!!submittingValue || (!!configuration.flag && !hasFeature(configuration.flag))}
              />
            </GridItem>
          ))}
        </GridContainer>
      </GridItem>
      <GridItem xs={12} mt={3}>
        <GridContainer>
          <GridItem xs={12} md={4} display="flex" alignItems="center">
            <Typography variant="h3" component="h2">
              i18n.projectCreate.projectTemplates.projectTemplatesHeader
            </Typography>
          </GridItem>
          <GridItem xs={12} md={3}>
            <VideoFormatSelect value={filter.videoFormat ?? 'All'} onChange={onVideoFormatChange} fullWidth />
          </GridItem>
          <GridItem xs={12} md={5}>
            <ProjectTemplatesSearchField />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <SearchResults
          id="projectTemplates"
          search={filter.search ?? undefined}
          count={paginatedProjectTemplates?.total ?? 0}
        >
          {error ? (
            <EmptyState
              iconType="CloudErrorIcon"
              title="i18n.projectTemplates.search.error.title"
              description="i18n.projectTemplates.search.error.description"
            />
          ) : paginatedProjectTemplates ? (
            <ProjectTemplatesContent
              projectTemplates={paginatedProjectTemplates?.items}
              Component={(props) => (
                <ProjectTemplateTile {...props} folderId={projectFolder.id} createProject={createProject} hideMenu />
              )}
            />
          ) : loading ? (
            <LoadingSpinner />
          ) : null}
        </SearchResults>
      </GridItem>
      {paginatedProjectTemplates && (
        <GridItem xs={12} display="flex" justifyContent="center">
          <ResultPagination
            total={paginatedProjectTemplates.total}
            limit={paginatedProjectTemplates.limit || paginatedProjectTemplates.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};
