import { useField } from 'formik';
import {
  type ComponentProps,
  type FC,
  type MouseEventHandler,
  type PropsWithChildren,
  useCallback,
  useState,
} from 'react';

import { MimeTypes, type VideoFormat } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  type AssetDropzoneProps,
  Box,
  DropzoneContainer,
  DropzoneStatus,
  NativeVideoPlayer,
  useUploadHint,
} from '@cofenster/web-components';

import { usePollingVideoAsset } from '../../../../api/hooks/videoAsset/usePollingVideoAsset';
import { useVideoAssetUpload } from '../../../../hooks/upload/useVideoAssetUpload';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';

type Props = ComponentProps<'div'> & {
  name: string;
  videoAssetId: string | undefined;
  videoFormat?: VideoFormat | undefined;
  assetDropzoneProps?: Partial<AssetDropzoneProps>;
};

export const VideoAssetUpload: FC<PropsWithChildren<Props>> = ({
  name,
  videoAssetId,
  videoFormat,
  assetDropzoneProps,
  children,
  ...rest
}) => {
  const videoAsset = usePollingVideoAsset(videoAssetId);
  const [{ value }, meta, { setTouched, setValue, setError }] = useField(name);
  const [videoUpload, progress] = useVideoAssetUpload();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const hintText = useUploadHint('video', videoFormat);

  const uploadFile = useCallback(
    async (file: File) => {
      const videoAssetId = await videoUpload(file, {
        videoFormat,
        videoFit: 'Fit',
      });
      setValue(videoAssetId);
      setTouched(true);
      setFileName(file.name);
    },
    [videoUpload, setValue, setTouched, videoFormat]
  );

  const confirmDeleteFile = useConfirmDialog({
    title: 'i18n.dialogs.DeleteAssetDialog.headline',
    content: 'i18n.dialogs.DeleteAssetDialog.text',
    confirm: 'i18n.dialogs.DeleteAssetDialog.button',
  });

  const deleteFile: MouseEventHandler = useCallback(
    async (event) => {
      event.stopPropagation();

      if (await confirmDeleteFile()) {
        setValue(undefined);
        setTouched(true);
      }
    },
    [setValue, setTouched, confirmDeleteFile]
  );

  const onError = useCallback(
    (error: string) => {
      setError(error);
      setTouched(true);
    },
    [setError, setTouched]
  );

  if (progress !== undefined) {
    return (
      <DropzoneContainer size="large" {...rest}>
        <DropzoneStatus
          status="uploading"
          text={fileName}
          hintText="i18n.sceneVideo.uploading"
          progress={progress || 0}
        />
      </DropzoneContainer>
    );
  }

  if (value && videoAsset) {
    return (
      <DropzoneContainer size="large" {...rest}>
        <Box fullHeight backgroundColor="grey50">
          {videoAsset?.videoUrl ? (
            <NativeVideoPlayer
              src={videoAsset.videoUrl}
              poster={videoAsset.thumbnailUrl ?? undefined}
              actions={['DOWNLOAD']}
            />
          ) : (
            <DropzoneStatus status="processing" text="i18n.global.upload.video.state.processing" />
          )}
          <AbsoluteDeleteIcon onClick={deleteFile} />
        </Box>
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="large" {...rest}>
      <AssetDropzone
        error={meta.error}
        mime={MimeTypes.video}
        maxSize={100 * 1024 * 1024}
        onFile={uploadFile}
        icon="CloudUploadIcon"
        text="i18n.common.upload.video.text"
        hintText={hintText}
        onError={onError}
        {...assetDropzoneProps}
      >
        {children}
      </AssetDropzone>
    </DropzoneContainer>
  );
};
