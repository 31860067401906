export const brandColors = {
  // Brand
  purple: '#8D5CE7',

  // Blues
  blue: '#4000F8',
  blue50: '#F5F2FF',
  blue100: '#ECE5FE',
  blue200: '#D9CCFE',
  blue300: '#C6B2FD',
  blue400: '#9F80FB',
  blueDark: '#221284',
  blue_alpha20: '#AD8FF033',
  blue_alpha40: '#AD8FF066',
  blue_alpha50: '#AD8FF080',

  // Greys
  grey50: '#F3F4F4',
  grey100: '#E6E9EA',
  grey200: '#CED3D5',
  grey300: '#B5BDC0',
  grey400: '#9DA7AB',
  grey500: '#849195',
  grey600: '#6C7C80',
  grey700: '#54666B',
  grey800: '#3B5056',
  grey900: '#233A41',
  carbon: '#0A242C',
  carbon_alpha5: '#0A242C0D',
  carbon_alpha10: '#0A242C1A',
  carbon_alpha30: '#0A242C4A',
  carbon_alpha50: '#0A242C80',
  carbon_alpha80: '#0A242CD0',

  // Linens
  linen: '#F5EEEA',
  linen50: '#EDE5E0',
  linen100: '#E1DAD5',

  // Tones
  negative: '#D33C32',
  negativeDark: '#B50D02',
  negativeLight: '#FFC5C1',
  positive: '#19E46A',
  positiveDark: '#19C65E',
  warning: '#f9e687',
  warningDark: '#EDEB37',

  // Miscellaneous
  white: '#ffffff',
  transparent: 'transparent',
  admin: 'deeppink',
} as const;

export type BrandColor = keyof typeof brandColors;
