import { type FC, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Icon, Responsive, Typography, styled } from '@cofenster/web-components';

import { useProjectNeedsRerender } from '../../../api/hooks/project/useProjectNeedsRerender';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';
import { RouterLink } from '../../../components/button/RouterLink';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { useIsRouteActive } from '../../../hooks/navigation/useIsRouteActive';
import { useDownloadExportedVideo } from '../../../hooks/project/useDownloadExportedVideo';
import { getRenderJobStatus } from '../../../hooks/project/useProjectExportStatus';
import { useI18n } from '../../../i18n';

import type { Project } from '../ProjectVideoContent';
import { StartRenderButton } from '../RenderProgress/StartRenderButton';
import { StartRenderIconButton } from '../RenderProgress/StartRenderIconButton';

import { MoreActionsButton } from './MoreActionsButton';
import { RenderJobProgressIconInformation } from './RenderJobProgressIconInformation';
import { RenderJobProgressTextInformation } from './RenderJobProgressTextInformation';
import { UpdatedAtInfo } from './UpdatedAtInfo';

const SubtitleExportCard = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderColor: theme.palette.brand.grey400,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  gap: theme.spacing(1),

  '&:hover ': {
    borderColor: theme.palette.brand.grey700,
  },

  '&:has(> [aria-current="true"])': {
    color: theme.palette.brand.blue,
    borderColor: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue100,
  },

  '&:hover:has(> [aria-current="true"]) ': {
    backgroundColor: theme.palette.brand.blue200,
  },

  '&:hover .icon-section-rendered, &:has(> [aria-current="true"]) .icon-section-rendered': {
    opacity: 0,
  },
  '&:hover .play-section-rendered, &:has(> [aria-current="true"]) .play-section-rendered': {
    opacity: 1,
  },
}));

// 1. Keep min size of 52px (our standard button height) even when there is no button there
const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2, 0, 0),
  alignItems: 'center',
  flex: 1,
  minHeight: 52, // 1
}));

const TitleAndInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 'auto',
}));

const IconSection = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.render-job-rendered:focus &': {
    opacity: 0,
  },
}));

const PlaySection = styled(IconSection)(() => ({
  opacity: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  '.render-job-rendered:focus &': {
    opacity: 1,
  },
}));

const StyledIconRouterLink = styled(RouterLink)(({ theme }) => ({
  position: 'relative',
  '&.render-job-rendered:hover': {
    transform: 'scale(1.1)',
  },
  margin: theme.spacing(0, 2),
}));

const useIsPlaying = () => {
  const { search } = useLocation();
  return new URLSearchParams(search).get('playerStatus') === 'playing';
};

export const ExportListCard: FC<{
  project: Project;
  subtitlesLanguage: string | null;
  renderJob: RenderJob | null | undefined;
  firstSceneId?: string | null;
}> = memo(({ project, renderJob, subtitlesLanguage, firstSceneId }) => {
  const projectId = project.id;
  const checkIsRouteActive = useIsRouteActive();
  const routeName = subtitlesLanguage ? 'projectVideoSubtitles' : 'projectVideo';
  const { languageNames } = useI18n();
  const status = getRenderJobStatus(renderJob);
  const { projectNeedsRerender, refetch } = useProjectNeedsRerender(projectId, subtitlesLanguage);
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to refetch only when status changes
  useEffect(() => {
    refetch();
  }, [status]);
  const hasPendingChanges = projectNeedsRerender?.status === 'NEEDS_RERENDER';
  const downloadVideo = useDownloadExportedVideo(project, 'projectFinalise', renderJob?.subtitlesLanguage ?? null);

  const isPlaying = useIsPlaying();
  const isRouteActive = checkIsRouteActive(routeName, { projectId, subtitlesLanguage });
  const isCurrentCardPlaying = isRouteActive && isPlaying;
  const playPauseQueryParams = isCurrentCardPlaying ? '?playerStatus=paused' : '?playerStatus=playing';

  return (
    <SubtitleExportCard>
      <StyledIconRouterLink
        to={routeName}
        params={{ projectId, subtitlesLanguage: subtitlesLanguage ?? undefined }}
        query={playPauseQueryParams}
        className={status === 'RENDERED' ? 'render-job-rendered' : undefined}
        aria-current={isCurrentCardPlaying}
      >
        <IconSection className={status === 'RENDERED' ? 'icon-section-rendered' : undefined}>
          {status === 'RENDERING' ? (
            <>
              <RenderJobProgressIconInformation
                status={renderJob?.status ?? 'Waiting'}
                step={renderJob?.step ?? 'Initializing'}
                stepProgress={renderJob?.stepProgress ?? 0}
              />
            </>
          ) : (
            <Icon size="m" type={subtitlesLanguage ? 'SubtitlesIcon' : 'MonitorPlayIcon'} />
          )}
        </IconSection>

        <PlaySection className={status === 'RENDERED' ? 'play-section-rendered' : undefined}>
          <Icon size="m" type={isCurrentCardPlaying ? 'PauseIcon' : 'PlayIcon'} />
        </PlaySection>
      </StyledIconRouterLink>
      <StyledRouterLink
        to={routeName}
        params={{ projectId, subtitlesLanguage: subtitlesLanguage ?? undefined }}
        aria-current={isRouteActive && !isPlaying}
        query="?playerStatus=paused"
      >
        <TitleAndInfo>
          <Typography
            variant="l"
            component="p"
            i18nParams={
              subtitlesLanguage
                ? {
                    language: languageNames.of(subtitlesLanguage),
                  }
                : undefined
            }
          >
            {subtitlesLanguage
              ? 'i18n.projectVideo.exportListCard.title.subtitledVideo'
              : 'i18n.projectVideo.exportListCard.title.originalVideo'}
          </Typography>
          {renderJob &&
            (status === 'RENDERING' || !renderJob.videoAsset ? (
              <RenderJobProgressTextInformation renderJob={renderJob} />
            ) : (
              <UpdatedAtInfo videoAsset={renderJob.videoAsset} hasPendingChanges={hasPendingChanges} />
            ))}
        </TitleAndInfo>
      </StyledRouterLink>
      {status === 'NOT_RENDERED' && (
        <StartRenderButton
          variant="tertiary"
          startIcon={<Icon type="UploadIcon" />}
          projectId={projectId}
          subtitlesLanguage={subtitlesLanguage}
        >
          i18n.common.exportVideo
        </StartRenderButton>
      )}
      {status === 'RENDERED' && (
        <>
          {renderJob?.videoAsset?.downloadUrl && (
            <TeamPermissionRestriction has="VideoDownload">
              <Responsive up="sm">
                <Button
                  variant="tertiary"
                  startIcon={<Icon type="DownloadIcon" />}
                  onClick={downloadVideo}
                  data-testid="download-button"
                >
                  i18n.global.download
                </Button>
              </Responsive>
            </TeamPermissionRestriction>
          )}
          <StartRenderIconButton
            key="rerender"
            icon="ArrowClockwiseIcon"
            data-testid="rerender-button"
            label="i18n.projectVideo.renderedVideo.update"
            projectId={projectId}
            subtitlesLanguage={subtitlesLanguage}
          />
          <MoreActionsButton
            project={project}
            renderJob={renderJob}
            firstSceneId={firstSceneId}
            subtitlesLanguage={subtitlesLanguage}
          />
        </>
      )}
    </SubtitleExportCard>
  );
});
