import { useGoto } from '@cofenster/web-components';
import { useCallback } from 'react';
import { useMoveScenesToExistingProject } from '../../../../../api/hooks/scene/useMoveScenesToExistingProject';
import { useDialogs } from '../../../../../contexts/dialogs';
import { useActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';
import { useUser } from '../../../../../contexts/user/useUser';
import { routes } from '../../../../../routes';

export const useOnMoveToProject = (projectId: string, sceneId: string) => {
  const { openDialog, closeDialog } = useDialogs();
  const actionSceneIds = useActionSceneIds(sceneId);

  const goto = useGoto();
  const { user } = useUser();
  const onMoveToNewProject = useCallback<(data: { sceneIds: string[]; keepSourceScenes: boolean }) => void>(
    ({ sceneIds, keepSourceScenes }) => {
      closeDialog('MoveScenesToProjectDialog');
      goto(
        routes.projectCreate,
        { folderId: user?.rootProjectFolder.id },
        {
          state: {
            copyScenes: keepSourceScenes ? sceneIds : undefined,
            moveScenes: keepSourceScenes ? undefined : sceneIds,
          },
          search: `from=${projectId}`,
        }
      );
    },
    [goto, user, closeDialog, projectId]
  );

  const [moveScenesToExistingProject] = useMoveScenesToExistingProject();
  const onMoveToExistingProject = useCallback<
    (data: { targetProjectId: string; sceneIds: string[]; keepSourceScenes: boolean }) => void
  >(
    async (data) => {
      await moveScenesToExistingProject(data);
      closeDialog('MoveScenesToProjectDialog');
    },
    [moveScenesToExistingProject, closeDialog]
  );

  return useCallback(() => {
    openDialog('MoveScenesToProjectDialog', {
      sceneIds: actionSceneIds,
      currentProjectId: projectId,
      onMoveToNewProject,
      onMoveToExistingProject,
    });
  }, [openDialog, projectId, actionSceneIds, onMoveToNewProject, onMoveToExistingProject]);
};
