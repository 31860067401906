import { styled } from '@mui/material';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { blurred } from './blurred';
import { checkerboard } from './checkerboard';

type ThumbnailBackgroundImageUrl = string;

export const ThumbnailContainer = styled(
  'div',
  preventForwardProps(['background'])
)<{ background?: ThumbnailBackgroundImageUrl | 'checkerboard' | null }>(({ theme, background }) => ({
  containerType: 'inline-size',

  aspectRatio: 16 / 9,
  borderRadius: theme.shape['borderRadius-l'],
  overflow: 'hidden',

  position: 'relative',

  ...(background && {
    '::before': {
      borderRadius: theme.shape['borderRadius-l'],
      content: '""',
      position: 'absolute',
      zIndex: 0,
      inset: 0,

      ...(background === 'checkerboard' ? checkerboard(theme) : blurred(background)),
    },
  }),

  '> [data-interactive]': {
    opacity: 0,
    transition: 'opacity 250ms ease',
  },

  '&:hover > [data-interactive], &:focus-within > [data-interactive]': {
    opacity: 1,
  },

  '& > *': {
    position: 'relative',
    zIndex: 0,
  },
}));
