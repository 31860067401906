import { type FC, useEffect } from 'react';

import { Headline, LoadingSpinner } from '@cofenster/web-components';

import { useProjectFolders } from '../../../api/hooks/projectFolder/useProjectFolders';
import { useListViewType } from '../../../contexts/listViewType/useListViewType';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

import { useProjectSearchFilter } from '../../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { FoldersGridView } from './FoldersGridView';
import { FoldersListView } from './FoldersListView';

type Props = {
  rootFolderId: string;
  isArchive: boolean;
};

export const Folders: FC<Props> = ({ rootFolderId, isArchive }) => {
  const { filter } = useProjectSearchFilter();
  const tracking = useWebManagerTracking();
  const { loading, projectFolders } = useProjectFolders(rootFolderId, {
    search: filter.search,
    status: filter.status,
  });
  const canCreateFolder = useTeamPermissionStatus({ has: 'FolderCreate' }) === 'ALLOWED';
  const { type } = useListViewType();

  useEffect(() => {
    if (loading || !projectFolders || !filter.search) return;
    tracking.trackEvent({
      event: 'foldersSearched',
      details: {
        searchText: filter.search,
        results: projectFolders.length,
      },
    });
  }, [loading, projectFolders, filter.search, tracking]);

  if (!projectFolders.length && loading) return <LoadingSpinner />;
  if (!projectFolders.length && isArchive) return null;
  if (!projectFolders.length && !canCreateFolder) return null;

  const Component = type === 'LIST' ? FoldersListView : FoldersGridView;

  return (
    <>
      <Headline variant="h3" component="h2">
        i18n.projects.headline.folders
      </Headline>
      <Component projectFolders={projectFolders} isArchive={isArchive} />
    </>
  );
};
