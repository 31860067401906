import { useEffect, useRef } from 'react';
import type { MediaLibraryFilter } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

export const useTrackFilterChange = (filter: MediaLibraryFilter) => {
  const previousRef = useRef<MediaLibraryFilter | null>(null);
  const tracking = useWebManagerTracking();

  useEffect(() => {
    if (previousRef.current === null) {
      previousRef.current = filter;
      return;
    }

    const prev = previousRef.current;

    if (prev.type !== filter.type) {
      tracking.trackEvent({
        event: 'mediaLibraryFiltered',
        details: {
          filterType: 'assetType',
          filterValue: filter.type,
        },
      });
    }

    if (prev.orderBy !== filter.orderBy) {
      tracking.trackEvent({
        event: 'mediaLibrarySorted',
        details: {
          sortBy: filter.orderBy,
        },
      });
    }

    previousRef.current = filter;
  }, [filter, tracking]);
};
