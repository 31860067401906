import { styled } from '@mui/material';
import type { FC } from 'react';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

const FullSizeImage = styled(
  'img',
  preventForwardProps(['objectFit'])
)<{ objectFit: 'cover' | 'contain' }>(({ objectFit }) => ({
  objectFit,
  width: '100%',
  height: '100%',
}));

type ImageAsset = { imageUrl?: string | null };
type StockImage = { thumbnailUrl: string };

export type ThumbnailImageProps = {
  title?: string | null;
  'data-testid'?: string;
  objectFit?: 'cover' | 'contain';
} & ({ imageAsset: ImageAsset; stockImage?: never } | { stockImage: StockImage; imageAsset?: never });

export const ThumbnailImage: FC<ThumbnailImageProps> = ({
  title,
  'data-testid': dataTestId = 'thumbnail-image',
  imageAsset,
  stockImage,
  objectFit = 'contain',
}) => {
  return (
    <FullSizeImage
      data-testid={dataTestId}
      src={imageAsset ? (imageAsset.imageUrl ?? undefined) : stockImage.thumbnailUrl}
      alt={title ?? ''}
      objectFit={objectFit}
    />
  );
};
