import { Formik, type FormikConfig, type FormikHelpers, type FormikProps, type FormikValues } from 'formik';
import { type ReactNode, useCallback, useRef } from 'react';

import { styled } from '@mui/material';
import { FormAutoSubmitter } from './FormAutoSubmitter';
import { FormError } from './FormError';
import { FormObserver } from './FormObserver';

import { useIsMounted } from '../../../hooks/dom/useIsMounted';
import { PreserveFormState } from './PreserveFormState';
import { useGetErrorMessage } from './useGetErrorMessage';

export { FormAutoSubmitter } from './FormAutoSubmitter';
export { useGetErrorMessage } from './useGetErrorMessage';

const StyledForm = styled('form')({
  width: '100%',
});

type FormProps<Values extends FormikValues> = FormikConfig<Values> & {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => Promise<unknown>;
  onChange?: (values: Values) => unknown;
  autoSubmit?: boolean;
  className?: string;
  castValuesToValidationSchema?: boolean;
  preserveFormState?: string;
  'data-testid'?: string;
  id?: string;
  children: ((formikProps: FormikProps<Values>) => ReactNode) | ReactNode | undefined;
};

export const Form = <Values extends FormikValues>({
  children,
  onSubmit,
  onChange,
  validationSchema,
  preserveFormState,
  castValuesToValidationSchema = true,
  enableReinitialize = true,
  validateOnChange = false,
  validateOnBlur = true,
  autoSubmit = false,
  className,
  'data-testid': dataTestId,
  id,
  ...rest
}: FormProps<Values>) => {
  const isSubmitting = useRef(false);
  const isMounted = useIsMounted();

  const errorMessage = useGetErrorMessage();
  const submit = useCallback(
    async (values: Values, formik: FormikHelpers<Values>) => {
      if (isSubmitting.current) return;
      if (validationSchema && castValuesToValidationSchema) {
        try {
          values = validationSchema.cast(values);
        } catch {
          // use the original values if casting throws an error for any reason
        }
      }
      formik.setStatus({ success: false, error: false });
      formik.setSubmitting(true);
      isSubmitting.current = true;
      try {
        const result = await onSubmit(values, formik);
        if (isMounted) {
          formik.setSubmitting(false);
          isSubmitting.current = false;
          formik.setStatus({ success: true, error: false });
        }
        return result;
      } catch (e) {
        if (isMounted) {
          formik.setSubmitting(false);
          isSubmitting.current = false;
          formik.setStatus({ success: false, error: errorMessage(e) });
        }
      }
    },
    [onSubmit, isMounted, validationSchema, castValuesToValidationSchema, errorMessage]
  );

  return (
    <Formik<Values>
      onSubmit={submit}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      enableReinitialize={enableReinitialize}
      {...rest}
    >
      {(formikProps) => (
        <StyledForm id={id} onSubmit={formikProps.handleSubmit} className={className} data-testid={dataTestId}>
          {typeof children === 'function' ? children(formikProps) : children}
          <FormError data-testid="form-submit-errors" />
          {autoSubmit && <FormAutoSubmitter />}
          {onChange && <FormObserver onChange={onChange} />}
          {preserveFormState && <PreserveFormState id={preserveFormState} />}
        </StyledForm>
      )}
    </Formik>
  );
};
