import { forwardRef } from 'react';
import { NativeVideoPlayer } from '../NativeVideoPlayer';

type VideoAsset = { videoUrl?: string | null; thumbnailUrl?: string | null };
type StockVideo = { thumbnailVideoUrl: string; posterUrl: string };

export type ThumbnailVideoProps = {
  'data-testid'?: string;
} & ({ videoAsset: VideoAsset; stockVideo?: never } | { stockVideo: StockVideo; videoAsset?: never });

export const ThumbnailVideo = forwardRef<HTMLVideoElement, ThumbnailVideoProps>(
  ({ 'data-testid': dataTestId = 'thumbnail-video', videoAsset, stockVideo }, ref) => {
    return (
      <NativeVideoPlayer
        data-testid={dataTestId}
        ref={ref}
        src={videoAsset ? (videoAsset.videoUrl ?? undefined) : stockVideo.thumbnailVideoUrl}
        poster={videoAsset ? (videoAsset.thumbnailUrl ?? undefined) : stockVideo.posterUrl}
        playsInline
        autoPlay={false}
        preload="metadata"
        loop
        muted
        controls={false}
        objectFit="contain"
      />
    );
  }
);
