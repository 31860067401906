import { LogoIcon, VisuallyHidden, styled } from '@cofenster/web-components';
import type { FC } from 'react';

const Triangle = styled('div')(({ theme }) => ({
  position: 'absolute !important' as 'absolute',
  top: 0,
  right: 0,
  width: 96,
  height: 80,
  clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
  backgroundColor: theme.palette.brand.carbon,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
}));

export const CofensterProjectTemplateIndicator: FC = () => (
  <Triangle>
    <LogoIcon color="white" size={48} />
    <VisuallyHidden>i18n.global.cofensterLogo</VisuallyHidden>
  </Triangle>
);
