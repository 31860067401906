import { type FC, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Dialog, DialogActions, DialogContent } from '@cofenster/web-components';
import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { TwoStepMediaLibrary } from '../../common/AddScene/TwoStepMediaLibrary';

export type PickMediaLibraryItemDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  assetType?: 'IMAGE' | 'VIDEO';
  onMediaLibraryItemPicked: (mediaLibraryItem: MediaLibraryItem) => Promise<unknown>;
  videoFormat: VideoFormat;
};

export const PickMediaLibraryItemDialog: FC<PickMediaLibraryItemDialogProps> = ({
  isOpen,
  closeDialog,
  assetType,
  onMediaLibraryItemPicked,
  videoFormat,
}) => {
  const onMediaLibraryItemPickedIternal = useCallback(
    async (mediaLibraryItem: MediaLibraryItem | null) => {
      if (mediaLibraryItem) {
        await onMediaLibraryItemPicked(mediaLibraryItem);
      }
      closeDialog();
    },
    [onMediaLibraryItemPicked, closeDialog]
  );
  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.common.mediaLibrary" size="l">
      <TwoStepMediaLibrary
        videoFormat={videoFormat}
        onMediaLibraryItemPicked={onMediaLibraryItemPickedIternal}
        assetType={assetType}
        ContentComponent={DialogContent}
        ActionsComponent={DialogActions}
      />
    </Dialog>
  );
};
