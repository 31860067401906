import type { ChangeEvent, DragEvent, FC, KeyboardEvent } from 'react';

import { AcceptTypes, MimeTypes } from '@cofenster/constants';
import { Button, type ButtonProps, DEFAULT_DROPZONE_MAX_SIZE } from '@cofenster/web-components';

export const FilePicker: FC<
  Pick<ButtonProps, 'variant' | 'fullWidth' | 'startIcon' | 'endIcon' | 'loading' | 'disabled' | 'children'> & {
    id: string;
    multiple?: boolean;
    maxSize?: number;
    video?: boolean;
    image?: boolean;
    audio?: boolean;
    font?: boolean;
    onFiles: (files: File[]) => unknown;
    ButtonComponent?: FC<ButtonProps>;
  }
> = ({
  id,
  onFiles,
  multiple = false,
  maxSize = DEFAULT_DROPZONE_MAX_SIZE,
  video = false,
  image = false,
  audio = false,
  font = false,
  ButtonComponent = Button,
  children,
  ...buttonProps
}) => {
  const isSizeValid = (file: File) => file.size > 0 && file.size <= maxSize;

  const isTypeValid = (file: File) =>
    (video && file.type in MimeTypes.video) ||
    (image && file.type in MimeTypes.image) ||
    (audio && file.type in MimeTypes.audio) ||
    (font && file.type in MimeTypes.font);

  const accept = [
    video && AcceptTypes.video,
    image && AcceptTypes.image,
    audio && AcceptTypes.audio,
    font && AcceptTypes.font,
  ]
    .filter(Boolean)
    .join(',');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.currentTarget.files ?? []).filter(isSizeValid);
    if (files.length) onFiles(multiple ? files : files.slice(0, 1));
  };

  const onDragOver = (event: DragEvent<HTMLButtonElement & HTMLLabelElement>) => {
    if (event.dataTransfer.types.includes('Files')) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'link';
    }
  };

  const onDrop = (event: DragEvent<HTMLButtonElement & HTMLLabelElement>) => {
    event.preventDefault();
    const dataTransfer = new DataTransfer();
    for (const file of Array.from(event.dataTransfer.files).filter(isTypeValid)) {
      dataTransfer.items.add(file);
    }
    const input = event.currentTarget.previousSibling as HTMLInputElement;
    input.files = dataTransfer.files;
    input.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement & HTMLLabelElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.currentTarget.click();
    }
  };

  return (
    <>
      <input id={id} type="file" hidden onChange={onInputChange} accept={accept} multiple={multiple} />
      <ButtonComponent
        htmlFor={id}
        component="label"
        onDragOver={onDragOver}
        onDrop={onDrop}
        onKeyDown={onKeyDown}
        {...buttonProps}
      >
        {children}
      </ButtonComponent>
    </>
  );
};
FilePicker.displayName = 'FilePicker';
