import { type ComponentProps, useCallback } from 'react';

import type { RenameDialog } from '../components/dialog';
import { useDialogs } from '../contexts/dialogs';

type UseRenameDialogProps = Omit<ComponentProps<typeof RenameDialog>, 'isOpen' | 'closeDialog' | 'onCancel'> &
  Partial<Pick<ComponentProps<typeof RenameDialog>, 'onCancel'>> & {
    propagateSubmitError?: boolean;
  };

export const useRenameDialog = (props: UseRenameDialogProps) => {
  const { openDialog } = useDialogs();

  return useCallback(
    ({
      title = props.title,
      titleI18nParams = props.titleI18nParams,
      subtitle = props.subtitle,
      cancelButtonContent = props.cancelButtonContent,
      submitButtonContent = props.submitButtonContent,
      variant = props.variant,
      onSubmit = props.onSubmit,
      onCancel = props.onCancel,
      initialValues = props.initialValues,
      propagateSubmitError = props.propagateSubmitError,
    }: Partial<UseRenameDialogProps> = {}) =>
      new Promise<boolean>((resolve, reject) => {
        openDialog('RenameDialog', {
          title,
          titleI18nParams,
          subtitle,
          cancelButtonContent,
          submitButtonContent,
          variant,
          onSubmit: async (values: Parameters<UseRenameDialogProps['onSubmit']>[0]) => {
            try {
              await onSubmit?.(values);
              resolve(true);
            } catch (ex) {
              if (propagateSubmitError) {
                reject(ex);
              } else {
                throw ex;
              }
            }
          },
          onCancel: async () => {
            try {
              await onCancel?.();
              resolve(false);
            } catch (ex) {
              reject(ex);
            }
          },
          initialValues,
        });
      }),
    [
      openDialog,
      props.title,
      props.titleI18nParams,
      props.subtitle,
      props.submitButtonContent,
      props.cancelButtonContent,
      props.variant,
      props.initialValues,
      props.propagateSubmitError,
      props.onSubmit,
      props.onCancel,
    ]
  );
};
