import { useCallback } from 'react';
import type { Project } from '../../api/hooks/project/useProject';
import { useUpdateProject } from '../../api/hooks/project/useUpdateProject';
import { useRenameDialog } from '../../hooks/useRenameDialog';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

export const useRenameProject = (
  project: Pick<Project, 'id' | 'name'>,
  teamId: string | undefined,
  source: 'projectDetails' | 'projectCard',
  refetchQuries: string[] = []
) => {
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', ...refetchQuries] });
  const tracking = useWebManagerTracking(teamId);

  const trackProjectRenamed = useCallback(
    (projectName: string) => {
      tracking.trackEvent({
        event: 'projectRenamed',
        details: {
          projectId: project.id,
          projectName,
          source,
        },
      });
    },
    [project, tracking, source]
  );

  const rename = useRenameDialog({
    initialValues: { name: project.name },
    onSubmit: async ({ name }) => {
      await updateProject(project.id, { name });
      trackProjectRenamed(name);
    },
    title: 'i18n.dialogs.renameProjectDialog.headline',
    subtitle: 'i18n.dialogs.renameProjectDialog.description',
    submitButtonContent: 'i18n.dialogs.renameProjectDialog.button',
  });

  return useCallback(() => rename(), [rename]);
};
