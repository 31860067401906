import { Formik, type FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { type AssetDropzoneProps, FormAutoSubmitter, Spacing, useUploadHint } from '@cofenster/web-components';

import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useCreateThemeVideoAsset } from '../../api/hooks/theme/useCreateThemeVideoAsset';
import { useDeleteThemeVideoAsset } from '../../api/hooks/theme/useDeleteThemeVideoAsset';
import type { ThemeVideoType } from '../../api/hooks/user/useMe';
import type { AccountTheme } from '../../contexts/user/useUser';
import { useFindVideoAssetOnTheme } from '../../hooks/useFindVideoOnTheme';
import { VideoAssetUpload } from '../form/input/upload/VideoAssetUpload';
import { PickAssetFromMediaLibraryButton } from './PickAssetFromMediaLibraryButton';

type Props = {
  videoType: ThemeVideoType;
  videoFormat: VideoFormat;
  theme: AccountTheme;
  size?: AssetDropzoneProps['size'];
  'data-testid'?: string;
};

const FORMIK_FIELD_NAME = 'videoAssetId';

export const IntroOutroUpload: FC<Props> = ({ videoFormat, videoType, theme, size, 'data-testid': dataTestId }) => {
  const themeVideoAsset = useFindVideoAssetOnTheme(theme, videoType, videoFormat);
  const hintText = useUploadHint('video', videoFormat);

  const dropzoneProps: Partial<AssetDropzoneProps> = useMemo(
    () => ({ hintText, size: size ?? 'small' }),
    [hintText, size]
  );

  const deleteThemeVideoAsset = useDeleteThemeVideoAsset();
  const createThemeVideoAsset = useCreateThemeVideoAsset();
  const createAsset = useCallback(
    (videoAssetId: string) =>
      videoFormat &&
      createThemeVideoAsset({
        themeId: theme.id,
        videoAssetId,
        videoFormat: videoFormat,
        videoType,
      }),
    [createThemeVideoAsset, theme.id, videoFormat, videoType]
  );

  const themeVideoAssetId = themeVideoAsset?.id;

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      const { [FORMIK_FIELD_NAME]: formVideoAssetId } = values;

      // An asset in the theme, but no asset in the form: delete the asset from
      // the theme.
      if (!formVideoAssetId && themeVideoAssetId) {
        await deleteThemeVideoAsset(themeVideoAssetId);
      }

      // An asset in the form, but no asset in the theme: add the asset to the
      // theme.
      else if (formVideoAssetId && !themeVideoAssetId) {
        await createAsset(formVideoAssetId);
      }
    },
    [deleteThemeVideoAsset, createAsset, themeVideoAssetId]
  );

  const onMediaLibraryItemPicked = useCallback(
    async (item: MediaLibraryItem | null) => {
      if (item?.videoAsset) {
        await handleSubmit({ [FORMIK_FIELD_NAME]: item.videoAsset.id });
      }
    },
    [handleSubmit]
  );

  return (
    <>
      <Formik initialValues={{ videoAssetId: themeVideoAssetId }} enableReinitialize onSubmit={handleSubmit}>
        {({ values }) => (
          <>
            <FormAutoSubmitter />
            <VideoAssetUpload
              data-testid={dataTestId}
              assetDropzoneProps={dropzoneProps}
              name={FORMIK_FIELD_NAME}
              videoFormat={videoFormat ?? undefined}
              videoAssetId={
                !values.videoAssetId || values.videoAssetId === themeVideoAssetId
                  ? themeVideoAsset?.videoAsset?.id || undefined
                  : values.videoAssetId
              }
            >
              {!themeVideoAsset?.videoAsset?.id && (
                <Spacing vertical={2}>
                  <PickAssetFromMediaLibraryButton
                    assetType="VIDEO"
                    onMediaLibraryItemPicked={onMediaLibraryItemPicked}
                    videoFormat={videoFormat}
                  />
                </Spacing>
              )}
            </VideoAssetUpload>
          </>
        )}
      </Formik>
    </>
  );
};
