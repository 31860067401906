import type { MediaLibraryFilter } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { createSearchFilterAndPaginationContext } from '../../contexts/searchFilterAndPagination/createSearchFilterAndPaginationContext';

export const {
  Provider: MediaLibrarySearchWithPaginationContext,
  useFilterAndPagination: useMediaLibraryFilterAndPagination,
} = createSearchFilterAndPaginationContext<Omit<MediaLibraryFilter, 'offset' | 'limit'>>({
  orderBy: {
    defaultValue: 'MOST_POPULAR',
  },
  search: {
    defaultValue: '',
  },
  type: {
    defaultValue: undefined,
  },
});
