import type { ProjectTemplatesFilter } from '../../api/hooks/projectTemplate/useProjectTemplates';
import { createSearchFilterAndPaginationContext } from '../searchFilterAndPagination/createSearchFilterAndPaginationContext';

export const {
  Provider: ProjectTemplatesSearchFilterProvider,
  useFilterAndPagination: useProjectTemplatesSearchFilter,
} = createSearchFilterAndPaginationContext<Omit<ProjectTemplatesFilter, 'offset' | 'limit'>>({
  tags: {
    defaultValue: [],
    serialize: (urlTags) => encodeURIComponent((urlTags || []).join(',')),
    deserialize: (urlTags) => decodeURIComponent(urlTags).split(','),
  },
  search: {
    defaultValue: '',
  },
  createdBy: {
    defaultValue: 'ANYONE',
  },
  videoFormat: {
    defaultValue: undefined,
  },
});
