import type { VideoFormat } from '@cofenster/constants';
import { useMemo } from 'react';
import { useCreateProject } from '../../../api/hooks/project/useCreateProject';
import { useCreateProjectFromProjectTemplate } from '../../../api/hooks/projectTemplate/useCreateProjectFromProjectTemplate';
import { useMoveScenesToNewProject } from '../../../api/hooks/scene/useMoveScenesToNewProject';
import { useI18n } from '../../../i18n';
import type { CreateMode } from '../CreateMode';

export const useCreateBasedOnMode = (create: CreateMode) => {
  const { translate } = useI18n();
  const [createProject, createProjectMetadata] = useCreateProject();
  const [createProjectFromProjectTemplate, createProjectFromProjectTemplateMetadata] =
    useCreateProjectFromProjectTemplate();
  const [moveScenesToNewProject, moveScenesToNewProjectMetadata] = useMoveScenesToNewProject();

  return useMemo<
    [
      (
        input:
          | { projectFolderId: string; name: string; videoFormat: VideoFormat }
          | { projectFolderId: string; name: string; projectTemplateId: string }
      ) => Promise<{ id: string } | undefined>,
      { loading: boolean },
    ]
  >(() => {
    switch (create.mode) {
      case 'create': {
        return [
          async (input) => {
            if ('videoFormat' in input) {
              const result = await createProject(input.projectFolderId, {
                name: input.name ?? translate('projectCreate.form.name.defaultValue'),
                videoFormat: input.videoFormat,
              });
              return result?.data?.createProject;
            }
            const result = await createProjectFromProjectTemplate(input.projectTemplateId, {
              projectFolderId: input.projectFolderId,
              name: input.name ?? translate('projectCreate.form.name.defaultValue'),
            });
            return result?.data?.createProjectFromProjectTemplate;
          },
          { loading: createProjectMetadata.loading || createProjectFromProjectTemplateMetadata.loading },
        ] as const;
      }
      case 'move':
      case 'copy': {
        return [
          async (input) => {
            if ('videoFormat' in input) {
              const result = await moveScenesToNewProject({
                projectName: input.name ?? translate('projectCreate.form.name.defaultValue'),
                keepSourceScenes: create.mode === 'copy',
                videoFormat: input.videoFormat,
                sceneIds: create.sceneIds,
              });
              return result?.data?.moveScenesToNewProject?.project;
            }
            const result = await moveScenesToNewProject({
              projectName: input.name ?? translate('projectCreate.form.name.defaultValue'),
              keepSourceScenes: create.mode === 'copy',
              projectTemplateId: input.projectTemplateId,
              sceneIds: create.sceneIds,
            });
            return result?.data?.moveScenesToNewProject?.project;
          },
          { loading: moveScenesToNewProjectMetadata.loading },
        ] as const;
      }
    }
  }, [
    create,
    translate,
    createProject,
    createProjectMetadata,
    createProjectFromProjectTemplate,
    createProjectFromProjectTemplateMetadata,
    moveScenesToNewProject,
    moveScenesToNewProjectMetadata,
  ]);
};
