import { useCallback, useMemo } from 'react';

import { type SceneType, useCreateScene as useCreateSceneMutation } from '../../../api/hooks/scene/useCreateScene';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

import type { SceneTypeProps } from './SceneTypeCard';

const useCreateSceneTracking = () => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (sceneId: string, sceneType: SceneType, assetId?: string) =>
      tracking.trackEvent({
        event: 'SceneCardCreated',
        details: {
          sceneId,
          sceneType,
          source: 'edit',
          fromMediaLibrary: !!assetId,
        },
      }),
    [tracking]
  );
};

export const useCreateScene = ({ projectId, index, onCreate }: SceneTypeProps) => {
  const [createScene, metadata] = useCreateSceneMutation({ awaitRefetchQueries: true });
  const trackCreation = useCreateSceneTracking();

  const createAndTrack = useCallback(
    async (type: SceneType, assetId?: string) => {
      const result = await createScene(projectId, { type, index, assetId });
      const sceneId = result.data?.createScene?.id;

      if (sceneId) {
        trackCreation(sceneId, type, assetId);
        onCreate?.(sceneId);
      }
    },
    [createScene, projectId, index, trackCreation, onCreate]
  );

  return useMemo(() => [createAndTrack, metadata] as const, [createAndTrack, metadata]);
};
