import type { FC } from 'react';

import { Icon, SpinningIcon, Tooltip } from '@cofenster/web-components';

import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';

export const MediaLibraryItemAssetStatus: FC<{
  status: NonNullable<MediaLibraryItem['videoAsset']>['status'] | NonNullable<MediaLibraryItem['imageAsset']>['status'];
}> = ({ status }) => {
  return status === 'Ready' ? null : status === 'Error' ? (
    <Tooltip title="i18n.mediaLibrary.asset.error">
      <span>
        <Icon type="WarningIcon" size="m" color="white" background={{ size: 'xl', color: 'negative' }} />
      </span>
    </Tooltip>
  ) : (
    <Tooltip title="i18n.global.processingIndicator">
      <span>
        <SpinningIcon type="LoadingIcon" size="m" color="white" background={{ size: 'xl', color: 'carbon' }} />
      </span>
    </Tooltip>
  );
};
