import { type ComponentProps, type FC, type ReactNode, useCallback, useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
  useI18n,
} from '@cofenster/web-components';

type Values = {
  name: string;
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const validations = {
    name: Yup.string()
      .trim()
      .max(250, translate('form.error.generic.maxLength', { max: 250 }))
      .required('i18n.form.error.generic.required'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

/**
 * @see useRenameDialog
 */
export const RenameDialog: FC<{
  isOpen: boolean;
  closeDialog: VoidFunction;
  onCancel?: () => unknown;
  onSubmit: (values: Values) => Promise<unknown>;
  title: DialogProps['title'];
  titleI18nParams?: DialogProps['titleI18nParams'];
  subtitle?: string;
  cancelButtonContent?: ReactNode;
  submitButtonContent?: ReactNode;
  variant?: ComponentProps<typeof Button>['variant'];
  initialValues: Values;
}> = ({
  isOpen,
  closeDialog,
  title,
  titleI18nParams,
  cancelButtonContent = 'i18n.global.cancel',
  submitButtonContent = 'i18n.global.save',
  variant = 'primary',
  subtitle,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const validationSchema = useValidationSchema();

  const [submitting, setSubmitting] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const onCancelClick = useCallback(async () => {
    try {
      setCancelling(true);
      await onCancel?.();
    } finally {
      setCancelling(false);
      closeDialog();
    }
  }, [onCancel, closeDialog]);

  const internalOnSubmit = useCallback(
    async (values: Values) => {
      setSubmitting(true);
      try {
        await onSubmit({ name: values.name });
        closeDialog();
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, closeDialog]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onCancelClick}
      data-testid="rename-dialog"
      title={title}
      titleI18nParams={titleI18nParams}
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={internalOnSubmit}>
        <DialogContent>
          {subtitle && (
            <Spacing bottom={3}>
              <Text variant="l" color="grey600" component="p">
                {subtitle}
              </Text>
            </Spacing>
          )}

          <FormTextField
            id="name"
            name="name"
            label="i18n.common.name"
            placeholder="i18n.common.name"
            autoFocus
            data-testid="rename-input"
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="tertiary"
            fullWidth
            onClick={onCancelClick}
            data-testid="rename-cancel-button"
            loading={cancelling}
            disabled={submitting}
          >
            {cancelButtonContent}
          </Button>
          <FormSubmitButton
            variant={variant}
            fullWidth
            data-testid="rename-dialog-submit-button"
            disabled={cancelling ? cancelling : undefined}
          >
            {submitButtonContent}
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
