import { isCancel } from 'axios';

export type UploadFileOptions = {
  onProgress?: (progressData: { progress: number }) => void;
  signal?: AbortController['signal'];
  method?: 'PUT' | 'POST';
};

const fetchWithProgress = (
  url: string,
  options: Omit<RequestInit, 'body'> & {
    body: XMLHttpRequestBodyInit;
  },
  onProgress: (progress: number) => unknown
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(options.method ?? 'GET', url);
    for (const [headerName, value] of Object.entries(options.headers ?? {})) {
      xhr.setRequestHeader(headerName, value);
    }
    xhr.onload = (e) => resolve(e.target);
    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = (event) => {
        const { loaded, total } = event;
        const progress = Math.floor((loaded / total) * 100) / 100;
        onProgress(progress);
      };
    }

    xhr.send(options.body);
  });
};

export const uploadFileXMLHttpRequest = (
  url: string,
  file: File | FormData,
  { onProgress, signal, method = 'PUT' }: UploadFileOptions = {}
) => {
  const contentType = 'type' in file ? file.type : undefined;
  const headers = contentType ? { 'Content-Type': contentType } : undefined;

  return fetchWithProgress(
    url,
    {
      body: file,
      headers,
      method,
      signal,
    },
    (progress) => onProgress?.({ progress })
  );
};

export const isCancellationError = isCancel;
