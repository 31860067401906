import { type FC, useEffect, useState } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import { AspectRatioBox, NativeVideoPlayer, styled, useSearchParams } from '@cofenster/web-components';

import type { VideoAsset } from '../../../api/generated';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';
import type { Project } from '../ProjectVideoContent';
import { ClearAspectRatioBox } from './ClearAspectRatioBox';

const useSyncPlayerStateWithQueryParams = (video: HTMLVideoElement | null, videoAssetId: string | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const playerStatus = searchParams.get('playerStatus');

  // If playerStatus isn't defined, then default to paused
  useEffect(() => {
    if (!playerStatus) setSearchParams({ playerStatus: 'paused' });
  }, [playerStatus, setSearchParams]);

  // Browser does not allow to play any audio without user activation
  // Switch to the paused state if there was no interaction on mount.
  useEffect(() => {
    // UserActivation API is not available on old Safari
    if (navigator.userActivation?.isActive) return;
    setSearchParams({ playerStatus: 'paused' });
  }, [setSearchParams]);

  // Propagate video state to query params
  useEffect(() => {
    if (!video) return;
    const onPlay = () => {
      searchParams.set('playerStatus', 'playing');
      setSearchParams(searchParams);
    };
    const onPause = () => {
      searchParams.set('playerStatus', 'paused');
      setSearchParams(searchParams);
    };
    video.addEventListener('play', onPlay);
    video.addEventListener('pause', onPause);

    return () => {
      video.removeEventListener('play', onPlay);
      video.removeEventListener('pause', onPause);
    };
  }, [searchParams, setSearchParams, video]);

  // Sync player with query params
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to check query param each time videoAsset changes
  useEffect(() => {
    if (!video) return;
    if (playerStatus === 'playing' && video.paused && navigator.userActivation?.isActive)
      video.play().catch(() => undefined);
    else if (playerStatus === 'paused' && !video.paused) video.pause();
  }, [playerStatus, videoAssetId, setSearchParams, video]);
};

const VideoPlayer: FC<{
  project: Project;
  videoAsset: Pick<VideoAsset, 'id' | 'videoUrl' | 'thumbnailUrl'> | undefined;
}> = ({ project, videoAsset }) => {
  const { aspectRatio } = VIDEO_FORMATS[project.videoFormat];

  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  useSyncPlayerStateWithQueryParams(video, videoAsset?.id);

  if (!videoAsset?.videoUrl) return null;

  return (
    <AspectRatioBox ratio={16 / 9} width="100%">
      <ClearAspectRatioBox>
        <AspectRatioBox ratio={aspectRatio} height="100%">
          <NativeVideoPlayer
            ref={setVideo}
            src={videoAsset.videoUrl}
            poster={videoAsset.thumbnailUrl ?? undefined}
            data-testid="video-rendered-player"
            objectFit="contain"
            actions={['FULLSCREEN']}
          />
        </AspectRatioBox>
      </ClearAspectRatioBox>
    </AspectRatioBox>
  );
};

const SingleColumnLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    minWidth: 500,
  },
}));

export const RenderedVideo: FC<{
  project: Project;
  renderJob: RenderJob | undefined;
}> = ({ project, renderJob }) => (
  <SingleColumnLayout>
    <VideoPlayer project={project} videoAsset={renderJob?.videoAsset ?? undefined} />
  </SingleColumnLayout>
);
