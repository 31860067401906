import { useCallback } from 'react';

import { isCancellationError, uploadFile } from '../../utilities/uploadFile';
import { uploadFileXMLHttpRequest } from '../../utilities/uploadFileXMLHttpRequest';

export const useFileUpload = () =>
  useCallback(
    async (
      uploadUrl: string,
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      provider: 'axios' | 'XMLHttpRequest' = 'axios'
    ) => {
      onProgress(uploadId, 0);

      try {
        const uploadFunction = provider === 'axios' ? uploadFile : uploadFileXMLHttpRequest;
        await uploadFunction(uploadUrl, file, {
          onProgress: ({ progress }) => onProgress(uploadId, progress),
          signal: abortSignal,
        });

        onProgress(uploadId, 1);
      } catch (error) {
        if (isCancellationError(error)) {
          onProgress(uploadId, 0);
          return undefined;
        }
        throw error;
      }

      return uploadId;
    },
    []
  );
