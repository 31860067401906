import { type FC, useEffect } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  LoadingSpinner,
  ResultPagination,
  SearchResults,
  Spacing,
} from '@cofenster/web-components';

import type { PaginatedProjects } from '../../api/generated';
import type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { ProjectBulkSelector } from '../../components/team/Projects/ProjectBulkSelector';
import { ProjectsGridView } from '../../components/team/Projects/ProjectsGridView';
import { ProjectsListView } from '../../components/team/Projects/ProjectsListView';
import { useListViewType } from '../../contexts/listViewType/useListViewType';
import { useProjectSearchFilter } from '../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

type Props = {
  projectFolder: ProjectFolder;
  isArchive: boolean;
  withOwner?: boolean;
  loading: boolean;
  paginatedProjects?: PaginatedProjects;
  error: boolean;
};

export const ProjectFolderView: FC<Props> = ({
  projectFolder,
  isArchive,
  withOwner,
  loading,
  paginatedProjects,
  error,
}) => {
  const tracking = useWebManagerTracking();
  const { type } = useListViewType();
  const { page, setPage, filter } = useProjectSearchFilter();

  useEffect(() => {
    if (loading || !paginatedProjects || !filter.search) return;
    tracking.trackEvent({
      event: 'projectsSearched',
      details: {
        searchText: filter.search,
        results: paginatedProjects.items.length,
        folderId: projectFolder.id,
      },
    });
  }, [loading, paginatedProjects, filter.search, tracking, projectFolder.id]);

  useEffect(() => {
    if (!paginatedProjects) return;
    tracking.trackEvent({
      event: 'projectsViewed',
      details: {
        searchText: filter.search,
        page: paginatedProjects.limit ? Math.floor(paginatedProjects.offset / paginatedProjects.limit) + 1 : 1,
        totalResults: paginatedProjects.total,
        status: filter.status,
        resultsOnPage: paginatedProjects.items.length,
        folderId: projectFolder.id,
      },
    });
  }, [paginatedProjects, filter, tracking, projectFolder.id]);

  if (loading) return <LoadingSpinner />;

  if (error) {
    return (
      <Spacing top={2}>
        <EmptyState
          iconType="NoOptionIcon"
          title="i18n.global.errorPage.headline"
          description="i18n.global.errorPage.content"
        />
      </Spacing>
    );
  }

  const projects = paginatedProjects?.items || [];

  if (isArchive && !projects.length) {
    return (
      <SearchResults
        id="projects"
        search={filter.search ?? undefined}
        count={paginatedProjects?.total ?? projects.length}
      >
        <EmptyState
          iconType="SearchIcon"
          title="i18n.projects.noArchived.headline"
          description="i18n.projects.noArchived.subline"
        />
      </SearchResults>
    );
  }

  return (
    <>
      <SearchResults
        id="projects"
        search={filter.search ?? undefined}
        count={paginatedProjects?.total ?? projects.length}
      >
        {type === 'GRID' ? (
          <ProjectsGridView
            projects={projects}
            folderId={projectFolder.id}
            archived={isArchive}
            withOwner={withOwner}
          />
        ) : (
          <ProjectsListView
            projects={projects}
            folderId={projectFolder.id}
            archived={isArchive}
            withOwner={withOwner}
          />
        )}
      </SearchResults>

      <GridContainer pt={4} justifyContent="center">
        <GridItem>
          {paginatedProjects && (
            <ResultPagination
              total={paginatedProjects.total}
              limit={paginatedProjects.limit || paginatedProjects.total}
              page={page}
              onChange={setPage}
            />
          )}
        </GridItem>
      </GridContainer>

      <ProjectBulkSelector projects={projects} />
    </>
  );
};
