import { styled, useTheme } from '@mui/material';
import {
  type CSSProperties,
  type ComponentProps,
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { LogoIcon } from '@cofenster/web-components';
import { useViewportWidth } from '../../../hooks/dom/useViewportWidth';
import { breakpointsThemeOptions } from '../../../theming/theme/breakpoints';
import { AdminLogoIcon } from '../../assets/logos/AdminLogoIcon';
import { IconButton } from '../../controls/Button/IconButton';
import { Chip } from '../../display/Chip';

export const SIDEBAR_WIDTH = 240;
const MOBILE_BREAKPOINT = breakpointsThemeOptions.values.sm;
const BREAKPOINT_MEDIA_QUERY = `(min-width: ${MOBILE_BREAKPOINT}px)`;

// 1. Make the sidebar spread the entire height of the viewport (no more, no
//    less), regardless of mobile or desktop.
// 2. Ensure the sidebar sits on top of the rest of the content, particularly
//    the sharing container and bulk selector.
// 3. Make the sidebar take ¾ of the viewport width on mobile.
// 4. Move the sidebar off-screen (to the left) when not open, and animate it
//    sliding it laterally when open.
// 5. Pad the right side of the screen that is not covered with the sidebar with
//    a pseudo-element so taps cannot go through when the sidebar is open.
// 6. Make the sidebar a fixed width (SIDEBAR_WIDTH) on desktop.
// 7. Make sure the sidebar is always visible on desktop.
// 8. Make sure that the sidebar content is removed from the tab index when the
//    sidebar is hidden.
// 9. Give the sidebar a view transition name so it can be animated with the
//    View Transition API. Unfortunately, the animation styles have to be
//    applied globally in `ThemeProvider` and cannot be defined here.
const Container = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',

  padding: theme.spacing(2),

  backgroundColor: theme.palette.brand.carbon,
  transition: 'transform 250ms',
  position: 'fixed',
  top: 0, // 1
  minHeight: '100%', // 1
  maxHeight: '100vh', // 1
  zIndex: theme.zIndex.overlay, // 2
  width: '75vw', // 3

  viewTransitionName: 'sidebar', // 9

  '&::before': {
    content: '""', // 5
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 'calc(100% + 0.5px)',
    width: 'calc(100vw - 75vw)',
    backdropFilter: 'blur(2px)',
    transform: 'scaleX(100%)',
    transition: 'transform 250ms, backdrop-filter 250ms',
  },

  '&[aria-hidden="true"]': { transform: 'translateX(-100%)' }, // 4
  '&[aria-hidden="true"]::before': { transform: 'scaleX(0)' },

  [theme.breakpoints.up('sm')]: {
    position: 'sticky', // 6
    height: '100vh',
    width: `${SIDEBAR_WIDTH}px`, // 6
    transform: 'translate(0)', // 7
    flexShrink: 0,

    '&::before': {
      display: 'none', // 7
    },
  },

  '[aria-hidden="true"] > &': {
    visibility: 'hidden', // 8
  },
}));

// 1. Make sure the toggle sit *above* the sidebar itself so that it can be
//    interacted with when the sidebar is open.
const MenuToggle = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: theme.zIndex.overlay + 1, // 1

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export type SidebarProps = ComponentProps<'div'> & { admin?: boolean };

const StyledChip = styled(Chip)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  margin: theme.spacing(-1.5, 'auto', 0),
}));

export const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({ children, admin, ...rest }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  // Mark the sidebar as visible on large screens since it only collapses on
  // small screens. Note that this check would need to be done differently with
  // SSR as the `window` object wouldn’t exist on the server.
  const isLargeScreen = window.matchMedia(BREAKPOINT_MEDIA_QUERY).matches;
  const [isSidebarVisible, setIsSidebarVisible] = useState(isLargeScreen);

  // Whenever the viewport gets resized, make sure to mark the sidebar as
  // visible if the viewport width passes the threshold, otherwise the sidebar
  // would be missing on desktop.
  const viewportWidth = useViewportWidth((viewportWidth) => setIsSidebarVisible(viewportWidth >= MOBILE_BREAKPOINT));

  // Whenever the pathname changes on a small screen, collapse the sidebar as
  // it means a navigation link was just tapped, so we should mimick a page
  // reload.
  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    if (viewportWidth < MOBILE_BREAKPOINT) setIsSidebarVisible(false);
  }, [viewportWidth, pathname]);

  const toggleSidebarVisibility = useCallback(() => setIsSidebarVisible((status) => !status), []);

  return (
    <>
      <Container {...rest} data-testid="sidebar" aria-hidden={!isSidebarVisible}>
        {admin ? <AdminLogoIcon /> : <LogoIcon color="white" />}
        {admin && (
          <StyledChip
            color="dark"
            label="Admin"
            size="small"
            style={{ '--chip-color': theme.palette.brand.admin } as CSSProperties}
          >
            Admin
          </StyledChip>
        )}
        {children}
      </Container>
      <MenuToggle
        backgroundColor="blurred"
        icon={isSidebarVisible ? 'CloseIcon' : 'ListIcon'}
        label="i18n.global.navigation.toggle"
        onClick={toggleSidebarVisibility}
        aria-pressed={isSidebarVisible}
        iconSize="l"
        data-testid="sidebar-toggle"
      />
    </>
  );
};
