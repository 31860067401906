import { EmptyState, GridContainer, GridItem } from '@cofenster/web-components';
import type { FC } from 'react';
import type { ProjectTemplate } from '../../api/hooks/projectTemplate/useProjectTemplates';
import { useProjectTemplatesSearchFilter } from '../../contexts/projectTemplateSearchFilter/ProjectTemplatesSearchFilterProvider';

export const ProjectTemplatesContent: FC<{
  projectTemplates: ProjectTemplate[] | undefined;
  Component: FC<{ projectTemplate: ProjectTemplate }>;
}> = ({ projectTemplates, Component }) => {
  const { filter } = useProjectTemplatesSearchFilter();
  if (!projectTemplates) return null;

  if (projectTemplates.length === 0) {
    if (!filter.search) {
      return <EmptyState iconType="XCircleIcon" title="i18n.projectTemplates.page.search.noResults.noSearch.title" />;
    }

    return (
      <EmptyState
        iconType="XCircleIcon"
        title="i18n.projectTemplates.page.search.noResults.title"
        titleProps={{
          i18nParams: {
            search: filter.search ?? '',
          },
        }}
        description="i18n.projectTemplates.page.search.noResults.description"
      />
    );
  }

  return (
    <GridContainer>
      {projectTemplates.map((projectTemplate) => (
        <GridItem xs={12} sm={6} md={4} key={projectTemplate.id}>
          <Component projectTemplate={projectTemplate} />
        </GridItem>
      ))}
    </GridContainer>
  );
};
