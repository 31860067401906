import { type FC, Fragment, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Container, GridItem, LoadingSpinner, styled } from '@cofenster/web-components';

import { useProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { RouterIconButton } from '../../components/button/RouterIconButton';
import { FullPageWithTitleAndActions } from '../../components/page/FullPageWithTitleAndActions';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { ProjectTemplatesSearchFilterProvider } from '../../contexts/projectTemplateSearchFilter/ProjectTemplatesSearchFilterProvider';
import { useI18n } from '../../i18n';
import type { ProjectCreateRouteParams, RouteName } from '../../routes';
import type { CreateMode } from './CreateMode';
import { NewProjectForm } from './NewProjectForm';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingBottom: theme.spacing(3),
}));

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const useSource = (folderId: string) => {
  const queryParams = useQueryParams();
  const from = queryParams.get('from');

  if (from === 'home') {
    return { to: 'home' as RouteName };
  }

  if (from) {
    return { to: 'project' as RouteName, params: { projectId: from } };
  }

  return { to: 'projectFolder' as RouteName, params: { folderId } };
};

// this prevents state loss on subsequent renders
const useReadOnceLocationState = <T = unknown>() => {
  const [state] = useState((useLocation() as { state: T | null }).state);
  return state;
};

const useCreateMode = () => {
  const { translate } = useI18n();

  const state = useReadOnceLocationState<{ copyScenes?: string[]; moveScenes?: string[] }>();
  const copyScenes = state?.copyScenes;
  const moveScenes = state?.moveScenes;

  return useMemo(() => {
    if (Array.isArray(copyScenes) && copyScenes.length && copyScenes.every((id) => typeof id === 'string')) {
      return {
        mode: 'copy',
        sceneIds: copyScenes,
        title: translate('projectCreate.header.copy', { scenesCount: copyScenes.length }),
      } as const;
    }

    if (Array.isArray(moveScenes) && moveScenes.length && moveScenes.every((id) => typeof id === 'string')) {
      return {
        mode: 'move',
        sceneIds: moveScenes,
        title: translate('projectCreate.header.move', { scenesCount: moveScenes.length }),
      } as const;
    }

    return { mode: 'create', title: translate('projectCreate.header') } as const;
  }, [copyScenes, moveScenes, translate]);
};

export const ProjectCreate: FC = () => {
  const { folderId } = useParams() as ProjectCreateRouteParams;
  const { projectFolder } = useProjectFolder(folderId);

  const source = useSource(folderId);

  const createMode = useCreateMode() satisfies CreateMode;

  return (
    <FullPageWithTitleAndActions
      title={createMode.title}
      rightActions={[
        <Fragment key="cancel">
          <RouterIconButton
            {...source}
            icon="CloseIcon"
            data-testid="create-project-cancel"
            label="i18n.global.cancel"
          />
        </Fragment>,
      ]}
    >
      <GridItem alignSelf="stretch" flex={1} display="flex" flexDirection="column">
        <StyledContainer>
          <CurrentTeamProvider team={projectFolder?.team ?? undefined}>
            <ProjectTemplatesSearchFilterProvider itemsPerPage={9}>
              {projectFolder ? (
                <NewProjectForm projectFolder={projectFolder} create={createMode} />
              ) : (
                <LoadingSpinner />
              )}
            </ProjectTemplatesSearchFilterProvider>
          </CurrentTeamProvider>
        </StyledContainer>
      </GridItem>
    </FullPageWithTitleAndActions>
  );
};
