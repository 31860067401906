import { useMemo } from 'react';
import { type Scene, useScenes } from '../scenes/useScenes';
import { useSelectedScenes } from './useSelectedScenes';

export const useActionSceneIds = (clickedOnSceneId: string) => {
  const { selectedItems } = useSelectedScenes();
  const { scenes } = useScenes();

  return useMemo(
    () => getActionSceneIds(clickedOnSceneId, scenes, selectedItems),
    [selectedItems, clickedOnSceneId, scenes]
  );
};

export const useActionScenes = (clickedOnSceneId: string) => {
  const { selectedItems } = useSelectedScenes();
  const { scenes } = useScenes();

  return useMemo(
    () => getActionScenes(clickedOnSceneId, scenes, selectedItems),
    [selectedItems, clickedOnSceneId, scenes]
  );
};

const getActionScenes = (clickedOnSceneId: string, scenes: Scene[], selectedItems: Scene[]) => {
  if (clickedOnSceneId === 'intro' || clickedOnSceneId === 'outro') return [];

  // If the clicked scene is not part of a multi-selection, perform action
  // only on that scene
  if (selectedItems.length === 0 || selectedItems.findIndex(({ id }) => id === clickedOnSceneId) === -1) {
    const clickedOnSceneScene = scenes.find((scene) => scene.id === clickedOnSceneId);
    return clickedOnSceneScene ? [clickedOnSceneScene] : [];
  }

  // `.toSorted(..)` is not fully supported everywhere
  // See: https://caniuse.com/mdn-javascript_builtins_array_tosorted
  // See: https://cofenster.sentry.io/issues/5858442334/events/55dc41b34893457285d6da06357d879f/
  return selectedItems.slice().sort((a, b) => scenes.indexOf(a) - scenes.indexOf(b));
};

export const getActionSceneIds = (clickedOnSceneId: string, scenes: Scene[], selectedItems: Scene[]) => {
  const actionScenes = getActionScenes(clickedOnSceneId, scenes, selectedItems);

  // If multiple scenes are selected, perform the action on all selected scenes
  return actionScenes.map(({ id }) => id);
};
