import { type FC, useCallback } from 'react';

import type { SelectChangeEvent } from '@cofenster/web-components';

import { CreatedBySelect } from '../../components/project/CreatedBySelect';

import type { ProjectsFilter } from '../../api/hooks/project/useSearchProjects';
import { useProjectSearchFilter } from '../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { HomeListTypeButtons } from './HomeListTypeButtons';

export const HomeSectionControls: FC = () => {
  const { filter, setFilter } = useProjectSearchFilter();
  const onCreatedByChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setFilter('createdBy', event.target.value as ProjectsFilter['createdBy']),
    [setFilter]
  );

  return (
    <>
      <CreatedBySelect value={filter.createdBy} onChange={onCreatedByChange} />
      <HomeListTypeButtons />
    </>
  );
};
