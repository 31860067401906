import { Icon, SearchField, VisuallyHidden } from '@cofenster/web-components';
import { useCallback } from 'react';
import { useProjectSearchFilter } from '../../contexts/projectSearchFilter/ProjectSearchFilterProvider';

// Because of the colorful background on which the search field is placed, we do
// not want to use a floating label since it looks a bit off; we still want the
// field to have an accessible name though, so we do that with a visually hidden
// label.
export const HomeSearchField = () => {
  const { filter, setFilter } = useProjectSearchFilter();
  const setSearch = useCallback((value: string) => setFilter('search', value), [setFilter]);

  return (
    <>
      <VisuallyHidden as="label" htmlFor="search-field-search-projects">
        i18n.home.search.label
      </VisuallyHidden>
      <SearchField
        id="search-projects"
        name="search-projects"
        label=""
        placeholder="i18n.home.search.label"
        search={filter.search ?? ''}
        onSearch={setSearch}
        alwaysExtended
        fullWidth
        autoFocus
        InputProps={{
          startAdornment: <Icon type="SearchIcon" size="m" color="grey600" />,
        }}
        data-testid="search-field"
      />
    </>
  );
};
