import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// biome-ignore lint/suspicious/noExplicitAny: can legitimately be anything
const identity = (value: any) => value;

export type FieldConverter<T> = {
  serialize?(value: T): string | undefined;
  deserialize?(value: string): T | undefined;
};

export type FieldConvertersMap<T extends {}> = {
  [K in keyof T]?: FieldConverter<T[K]>;
};

const serialize = <T extends {}>(
  currentSearch: string,
  state: T,
  serializers: FieldConvertersMap<T>,
  defaults: T
): string => {
  // preserve all existing parameters not controlled by state
  const urlSearchParams = new URLSearchParams(currentSearch);

  Object.entries(state)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .forEach(([key, value]) => {
      const serializer = serializers[key as keyof T]?.serialize ?? identity;
      const serializedValue = serializer(value as T[keyof T]);

      if (serializedValue === '' || !value || defaults[key as keyof T] === value) {
        urlSearchParams.delete(key);
        return;
      }

      urlSearchParams.set(key, serializedValue);
    });

  return urlSearchParams.toString();
};

const deserialize = <T extends {}>(
  value: string | null,
  serializers: FieldConvertersMap<T>,
  keys: (keyof T)[]
): T | null => {
  if (!value) return null;
  const urlSearchParams = new URLSearchParams(value);
  const deserialized = Object.fromEntries(
    urlSearchParams
      .entries()
      // Filter out all keys not controlled by the state
      .filter(([key]) => keys.includes(key as keyof T))
      .map(([key, value]) => {
        const deserializer = serializers[key as keyof T]?.deserialize ?? identity;
        return [key, deserializer(value)];
      })
  ) as T;

  return Object.keys(deserialized).length === 0 ? null : deserialized;
};

export function useUrlParameters<T extends {}>(
  initialState: T,
  serializers: FieldConvertersMap<T>,
  keys: (keyof T)[]
): [T, (state: T) => void] {
  const navigate = useNavigate();
  const { search } = useLocation();
  const currentValue = useMemo(
    () => ({
      ...initialState,
      ...deserialize<T>(search, serializers, keys),
    }),
    [search, serializers, keys, initialState]
  );
  const [state, setState] = useState<T>(currentValue ?? initialState);

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (currentValue && !isEqual(currentValue, state)) {
      setState(currentValue);
    }
  }, [state, currentValue]);

  const onChange = useCallback(
    (value: T) => {
      const serializedValue = serialize(search, value, serializers, initialState);
      const params = new URLSearchParams(serializedValue);
      setState(value);
      navigate(`?${params.toString()}`);
    },
    [navigate, search, serializers, initialState]
  );

  return useMemo(() => [state, onChange], [state, onChange]);
}
