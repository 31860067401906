import {
  Button,
  DialogActions,
  DialogContent,
  EmptyState,
  Form,
  FormError,
  FormSubmitButton,
  GridContainer,
  GridItem,
  Icon,
  LoadingSpinner,
  ResultPagination,
  SearchField,
  SearchResults,
  type SelectChangeEvent,
  Typography,
  VisuallyHidden,
} from '@cofenster/web-components';
import { type FC, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import * as Yup from 'yup';
import { type ProjectsFilter, useSearchProjects } from '../../../api/hooks/project/useSearchProjects';
import {
  ProjectSearchFilterProvider,
  useProjectSearchFilter,
} from '../../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { CreatedBySelect } from '../../project/CreatedBySelect';
import { BlankFieldset } from './BlankFieldset';
import { ProjectTile } from './ProjectTile';

const validationSchema = Yup.object().shape({
  targetProjectId: Yup.string().required('i18n.form.error.generic.required'),
});

export type PickExistingProjectValues = Yup.InferType<typeof validationSchema>;

const initialValues: PickExistingProjectValues = {
  targetProjectId: '',
};

export const PickExistingProject: FC<{
  currentProjectId: string;
  onCancel: () => unknown;
  onPick: (values: PickExistingProjectValues) => unknown;
}> = ({ currentProjectId, onCancel, onPick }) => {
  const [actions, setActions] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <DialogContent>
        <Form
          id="move-scenes-to-project-existing-project-form"
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async ({ targetProjectId }) =>
            onPick({
              targetProjectId,
            })
          }
          preserveFormState="move-scenes-to-project-existing-project"
          validateOnChange
        >
          <ProjectSearchFilterProvider itemsPerPage={9}>
            <ExistingProjectStepContent currentProjectId={currentProjectId} />
          </ProjectSearchFilterProvider>
          {actions &&
            createPortal(
              /* this enables content within to access formik context */
              <>
                <Button type="button" variant="tertiary" onClick={onCancel}>
                  i18n.global.back
                </Button>
                <FormSubmitButton
                  autoDisable
                  data-testid="pick-existing-project"
                  form="move-scenes-to-project-existing-project-form"
                  variant="primary"
                >
                  i18n.common.move
                </FormSubmitButton>
              </>,
              actions
            )}
        </Form>
      </DialogContent>
      <DialogActions ref={setActions} style={{ justifyContent: 'flex-end' }} />
    </>
  );
};

const ExistingProjectStepContent: FC<{ currentProjectId: string }> = ({ currentProjectId }) => {
  const { filter, setFilter, page, setPage } = useProjectSearchFilter();
  const setSearch = useCallback((search: string) => setFilter('search', search), [setFilter]);
  const setCreatedBy = useCallback(
    (createdBy: ProjectsFilter['createdBy']) => setFilter('createdBy', createdBy),
    [setFilter]
  );

  const finalFilter = useMemo(
    () => ({
      ...filter,
      excludeProjectIds: [currentProjectId],
    }),
    [filter, currentProjectId]
  );
  const { paginatedProjects, loading, error } = useSearchProjects(finalFilter, {});

  const onCreatedByChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setCreatedBy(event.target.value as ProjectsFilter['createdBy']),
    [setCreatedBy]
  );

  return (
    <GridContainer alignItems="flex-start">
      <GridItem xs={12}>
        <SearchField
          data-testid="project-search-field"
          alwaysExtended
          placeholder=""
          label="i18n.dialogs.MoveScenesToProjectDialog.decisionStep.searchProjectsInputPlaceholder"
          fullWidth
          search={filter.search ?? ''}
          onSearch={setSearch}
          InputProps={{
            startAdornment: <Icon type="SearchIcon" size="m" color="grey600" />,
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GridContainer display="flex" flexDirection={{ xs: 'column-reverse', sm: 'row' }} alignItems={{ sm: 'center' }}>
          <GridItem xs={12} sm={6} md={8}>
            <Typography
              variant="h5"
              component="h3"
              i18nParams={{
                count: paginatedProjects?.total ?? 0,
              }}
            >
              i18n.dialogs.MoveScenesToProjectDialog.search.results
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CreatedBySelect value={filter.createdBy} onChange={onCreatedByChange} fullWidth />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} alignItems="center">
        <SearchResults id="target-project" count={paginatedProjects?.total ?? 0} search={filter.search ?? ''}>
          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <EmptyState
              iconType="CloudErrorIcon"
              title="i18n.dialogs.MoveScenesToProjectDialog.search.error.title"
              description="i18n.dialogs.MoveScenesToProjectDialog.search.error.description"
            />
          ) : paginatedProjects?.items.length === 0 ? (
            <EmptyState
              iconType="SearchIcon"
              title="i18n.dialogs.MoveScenesToProjectDialog.search.noResults.title"
              description="i18n.dialogs.MoveScenesToProjectDialog.search.noResults.description"
            />
          ) : (
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer spacing={1} rowSpacing={1} component={BlankFieldset}>
                  <VisuallyHidden as="legend">
                    i18n.dialogs.MoveScenesToProjectDialog.decisionStep.targetProjectRadioLegend
                  </VisuallyHidden>
                  {paginatedProjects?.items?.map((project) => (
                    <GridItem key={project.project.id} xs={12} sm={6} md={4}>
                      <ProjectTile extendedProject={project} />
                    </GridItem>
                  ))}
                </GridContainer>
              </GridItem>
              {paginatedProjects && (
                <GridItem xs={12} display="flex" justifyContent="center">
                  <ResultPagination
                    total={paginatedProjects.total}
                    limit={paginatedProjects.limit || paginatedProjects.total}
                    page={page}
                    onChange={setPage}
                  />
                </GridItem>
              )}
            </GridContainer>
          )}
        </SearchResults>
      </GridItem>
      <GridItem xs={12}>
        <FormError data-testid="form-submit-errors" />
      </GridItem>
    </GridContainer>
  );
};
