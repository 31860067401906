import type { VideoFormat } from '@cofenster/constants';
import { Button, type ButtonProps, Icon } from '@cofenster/web-components';
import { type FC, type MouseEventHandler, useCallback } from 'react';
import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useDialogs } from '../../contexts/dialogs';

export const PickAssetFromMediaLibraryButton: FC<
  {
    videoFormat: VideoFormat;
    onMediaLibraryItemPicked: (mediaLibraryItem: MediaLibraryItem | null) => Promise<unknown>;
    assetType: 'VIDEO' | 'IMAGE';
  } & Omit<ButtonProps, 'onClick' | 'startIcon'>
> = ({ videoFormat, onMediaLibraryItemPicked, assetType }) => {
  const { openDialog } = useDialogs();

  const onClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog('PickMediaLibraryItemDialog', {
        assetType,
        onMediaLibraryItemPicked,
        videoFormat,
      });
    },
    [openDialog, onMediaLibraryItemPicked, videoFormat, assetType]
  );

  return (
    <Button variant="secondary" onClick={onClick} startIcon={<Icon type="MediaLibraryIcon" size="s" />}>
      i18n.mediaLibrary.pickAssetButton
    </Button>
  );
};
