import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
} from '@cofenster/web-components';
import { useCreateTemplateFromProject } from '../../../api/hooks/projectTemplate/useCreateTemplateFromProject';

export type CreateProjectTemplateFromProjectDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectId: string;
  projectName: string;
  onTemplateCreated: (projectTemplateId: string, templateProjectId: string) => void;
};

type Values = {
  name: string;
  description?: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().notRequired(),
});

const useInitialValues = (name: string) => {
  return useMemo<Values>(
    () => ({
      name,
    }),
    [name]
  );
};

const useSubmit = (
  projectId: string,
  onTemplateCreated: (projectTemplateId: string, templateProjectId: string) => void,
  closeDialog: VoidFunction
) => {
  const { createProjectTemplateFromProject } = useCreateTemplateFromProject();

  return useCallback(
    async (values: Values) => {
      const result = await createProjectTemplateFromProject(projectId, {
        name: values.name,
        description: values.description,
      });
      const templateProjectId = result?.project.id;
      const projectTemplateId = result?.id;
      if (!projectTemplateId || !templateProjectId) throw new Error('Unexpected error while creating project template');
      closeDialog();
      onTemplateCreated(projectTemplateId, templateProjectId);
    },
    [createProjectTemplateFromProject, projectId, onTemplateCreated, closeDialog]
  );
};

export const CreateProjectTemplateFromProjectDialog: FC<CreateProjectTemplateFromProjectDialogProps> = ({
  isOpen,
  closeDialog,
  projectId,
  projectName,
  onTemplateCreated,
}) => {
  const initialValues = useInitialValues(projectName);
  const onSubmit = useSubmit(projectId, onTemplateCreated, closeDialog);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="create-project-template-from-project-dialog"
      title="i18n.projectTemplates.createProjectTemplateFromProject.dialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.projectTemplates.createProjectTemplateFromProject.dialog.subheadline
            </Text>
          </Spacing>
          <FormTextField
            id="name"
            name="name"
            label="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.name"
            data-testid="create-project-template-from-project-dialog-name"
            fullWidth
          />
          <FormTextField
            id="description"
            name="description"
            label="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.description"
            placeholder="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.descriptionPlaceholder"
            data-testid="create-project-template-from-project-dialog-description"
            fullWidth
            multiline
            rows={5}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton fullWidth data-testid="create-project-template-from-project-dialog-confirm">
            i18n.projectTemplates.createProjectTemplateFromProject.dialog.submitButton
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
