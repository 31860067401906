import { useCallback, useMemo } from 'react';

import { type CreateMediaLibraryItemsMutationVariables, useCreateMediaLibraryItemsMutation } from '../../generated';

export const useCreateMediaLibraryItem = () => {
  const [createMediaLibraryItems, metadata] = useCreateMediaLibraryItemsMutation({
    refetchQueries: ['MediaLibraryItems'],
  });

  const mutation = useCallback(
    async (inputs: CreateMediaLibraryItemsMutationVariables['inputs']) =>
      createMediaLibraryItems({ variables: { inputs } }),
    [createMediaLibraryItems]
  );

  return useMemo(() => [mutation, metadata] as const, [mutation, metadata]);
};
