import { type FC, type ReactNode, useCallback } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  LoadingSpinner,
  ResultPagination,
  SearchResults,
  Typography,
  styled,
} from '@cofenster/web-components';

import {
  type MediaLibraryFilter,
  type MediaLibraryItem,
  type MediaLibraryItemsQueryData,
  useMediaLibraryItems,
} from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';

import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';
import { MediaLibraryAssetTypeSelect } from './MediaLibraryAssetTypeSelect';
import { MediaLibraryEmptyState } from './MediaLibraryEmptyState';
import { MediaLibraryOrderBySelect } from './MediaLibraryOrderBySelect';
import { MediaLibrarySearchField } from './MediaLibrarySearchField';
import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';
import { useHasAnyMediaLibraryItems } from './useHasAnyMediaLibraryItems';
import { useTrackFilterChange } from './useTrackFilterChange';

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const TypographyWhiteSpaceNoWrap = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
}));

const useOnMediaLibraryItemsQueryCompleted = () => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (filter: MediaLibraryFilter | undefined, mediaLibraryItemsQueryData: MediaLibraryItemsQueryData) => {
      if (!filter || !filter.search || !mediaLibraryItemsQueryData) return;
      tracking.trackEvent({
        event: 'mediaLibraryFiltered',
        details: {
          searchTerm: filter.search,
          resultsCount: mediaLibraryItemsQueryData.total ?? 0,
        },
      });
    },
    [tracking]
  );
};

export const MediaLibrarySearchAndPagination: FC<{
  Results: FC<{ mediaLibraryItems: MediaLibraryItem[] }>;
  emptyState?: ReactNode;
  hideTypeFilter?: boolean;
}> = ({ Results, emptyState = <MediaLibraryEmptyState variant="solid" />, hideTypeFilter }) => {
  const { filter, page, setPage } = useMediaLibraryFilterAndPagination();
  useTrackFilterChange(filter);

  const onCompleted = useOnMediaLibraryItemsQueryCompleted();
  const { paginatedMediaLibraryItems, loading, error } = useMediaLibraryItems(filter, onCompleted);
  const { hasAnyMediaLibraryItems, loading: hasAnyMediaLibraryItemsLoading } = useHasAnyMediaLibraryItems(
    hideTypeFilter ? { type: filter.type } : undefined
  );

  if (hasAnyMediaLibraryItemsLoading) return <LoadingSpinner />;

  if (!hasAnyMediaLibraryItems) {
    return emptyState;
  }

  return (
    <GridContainer textAlign="left">
      <GridItem md={hideTypeFilter ? 9 : 6} xs={12}>
        <FlexContainer>
          <TypographyWhiteSpaceNoWrap
            i18nParams={{ count: paginatedMediaLibraryItems?.total ?? 0 }}
            variant="h4"
            component="h2"
          >
            {filter.type === 'IMAGE'
              ? 'i18n.mediaLibrary.search.total.image'
              : filter.type === 'VIDEO'
                ? 'i18n.mediaLibrary.search.total.video'
                : 'i18n.mediaLibrary.search.total'}
          </TypographyWhiteSpaceNoWrap>
          <MediaLibrarySearchField />
        </FlexContainer>
      </GridItem>
      {!hideTypeFilter && (
        <GridItem md={3} xs={6}>
          <MediaLibraryAssetTypeSelect />
        </GridItem>
      )}
      <GridItem md={3} xs={hideTypeFilter ? 12 : 6}>
        <MediaLibraryOrderBySelect />
      </GridItem>
      <GridItem xs={12}>
        <SearchResults id="media-library" count={paginatedMediaLibraryItems?.total ?? 0} search={filter.search ?? ''}>
          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <EmptyState
              iconType="CloudErrorIcon"
              title="i18n.mediaLibrary.search.error.title"
              description="i18n.mediaLibrary.search.error.description"
            />
          ) : (
            <Results mediaLibraryItems={paginatedMediaLibraryItems?.items ?? []} />
          )}
        </SearchResults>
      </GridItem>
      {paginatedMediaLibraryItems && (
        <GridItem xs={12} display="flex" justifyContent="center">
          <ResultPagination
            total={paginatedMediaLibraryItems.total}
            limit={paginatedMediaLibraryItems.limit || paginatedMediaLibraryItems.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};
