import { type FC, useCallback } from 'react';

import { Responsive, SearchField, type SelectChangeEvent, useI18n } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolder';
import { CreatedBySelect } from '../CreatedBySelect';
import { StatusSelect } from '../StatusSelect';

import type { ProjectsFilter } from '../../../api/hooks/project/useSearchProjects';
import { useProjectSearchFilter } from '../../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { ProjectFolderIcons } from './ProjectFolderIcons';

type ProjectFolderIconsProps = {
  projectFolder?: ProjectFolder;
};

export const ProjectFolderIconsWithSearch: FC<ProjectFolderIconsProps> = ({ projectFolder }) => {
  const { translate } = useI18n();
  const { filter, setFilter } = useProjectSearchFilter();

  const searchLabel = translate('global.search.term', { term: translate('common.projects') });
  const onCreatedByChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setFilter('createdBy', event.target.value as ProjectsFilter['createdBy']),
    [setFilter]
  );
  const onStatusChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setFilter('status', event.target.value as ProjectsFilter['status']);
    },
    [setFilter]
  );
  const setSearch = useCallback((search: string) => setFilter('search', search), [setFilter]);

  return (
    <ProjectFolderIcons>
      <SearchField label={searchLabel} id="projects" name="project" search={filter.search ?? ''} onSearch={setSearch} />

      {projectFolder?.team && (
        <Responsive up="md">
          <CreatedBySelect value={filter.createdBy} onChange={onCreatedByChange} />
        </Responsive>
      )}

      <Responsive up="sm">
        <StatusSelect value={filter.status} onChange={onStatusChange} />
      </Responsive>
    </ProjectFolderIcons>
  );
};
