import { useCallback } from 'react';
import type { UpdateProjectFolderMutation } from '../../api/generated';
import type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolders';
import { useUpdateProjectFolder } from '../../api/hooks/projectFolder/useUpdateProjectFolder';
import { useRenameDialog } from '../useRenameDialog';
import { useWebManagerTracking } from '../useWebManagerTracking';

export const useRenameProjectFolder = (projectFolder: ProjectFolder, source: 'folderCard' | 'folderDetails') => {
  const updateProjectFolder = useUpdateProjectFolder();
  const tracking = useWebManagerTracking(projectFolder.team?.id);

  const trackFolderRenamed = useCallback(
    (data: UpdateProjectFolderMutation) => {
      tracking.trackEvent({
        event: 'folderRenamed',
        details: {
          teamId: data.updateProjectFolder.team?.id,
          teamName: data.updateProjectFolder.team?.name,
          folderId: data.updateProjectFolder.id,
          folderName: data.updateProjectFolder.name,
          folderType: data.updateProjectFolder.team ? 'team' : 'private',
          source,
        },
      });
    },
    [tracking, source]
  );

  const renameFolder = useRenameDialog({
    initialValues: { name: projectFolder.name },
    onSubmit: async ({ name }: { name: string }) => {
      const response = await updateProjectFolder(projectFolder.id, { name });
      if (response.data?.updateProjectFolder) {
        trackFolderRenamed(response.data);
      }
    },
    title: 'i18n.mediaLibrary.rename.title',
    subtitle: 'i18n.mediaLibrary.rename.subtitle',
    submitButtonContent: 'i18n.mediaLibrary.rename.renameButton',
  });

  return useCallback(() => renameFolder(), [renameFolder]);
};
