import { styled } from '@mui/material';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

const ANCHOR_TO_FLEX_STYLE = {
  TopLeft: {
    alignItems: 'start',
    justifyContent: 'start',
  },
  TopRight: {
    alignItems: 'start',
    justifyContent: 'end',
  },
  BottomLeft: {
    alignItems: 'end',
    justifyContent: 'start',
  },
  BottomRight: {
    alignItems: 'end',
    justifyContent: 'end',
  },
  Center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const ThumbnailOverlayContainer = styled(
  'div',
  preventForwardProps(['anchor'])
)<{
  anchor?: keyof typeof ANCHOR_TO_FLEX_STYLE;
  'data-interactive'?: boolean;
}>(({ theme, anchor = 'Center' }) => ({
  position: 'absolute !important' as 'absolute',
  inset: theme.spacing(2),
  '@container(width < 240px)': {
    inset: theme.spacing(1),
  },

  display: 'flex',
  gap: theme.spacing(1),

  pointerEvents: 'none',
  '& > *': {
    pointerEvents: 'auto',
  },

  ...ANCHOR_TO_FLEX_STYLE[anchor],
}));
