import { styled } from '@mui/material';
import type { CSSProperties, FC } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import { preventForwardProps } from '../../../utilities';
import { NativeVideoPlayer } from '../NativeVideoPlayer';
import { blurred } from '../Thumbnail/blurred';
import { checkerboard } from '../Thumbnail/checkerboard';

type Image = {
  type: 'image';
  imageUrl: string;
  alt?: string | null;
};

type Video = {
  type: 'video';
  videoUrl: string;
  posterUrl: string;
};

type Asset = Video | Image;

const Image = styled('img')(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
  maxHeight: '100%',
  zIndex: theme.zIndex.above,
}));

const ImageAssetPreview: FC<{ asset: Image }> = ({ asset }) => <Image src={asset.imageUrl} alt={asset.alt ?? ''} />;

const StyledNativeVideoPlayer = styled(NativeVideoPlayer)(({ theme }) => ({
  zIndex: theme.zIndex.above,
}));

const VideoAssetPreview: FC<{ asset: Video }> = ({ asset }) => (
  <StyledNativeVideoPlayer
    src={asset.videoUrl}
    poster={asset.posterUrl}
    actions={['DOWNLOAD', 'FULLSCREEN', 'PLAYBACK_RATE']}
    autoPlay
    objectFit="contain"
  />
);

// 1. Weird hack for Safari to honor the rounded corner on that element.
//    See: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
const Container = styled(
  'div',
  preventForwardProps(['background'])
)<{ background?: string | 'checkerboard' | null }>(({ theme, background }) => ({
  position: 'relative',
  aspectRatio: 'var(--aspect-ratio)',
  maxHeight: 'min(50vh, var(--video-height))',
  objectFit: 'contain',
  margin: 'auto',
  borderRadius: theme.shape['borderRadius-l'],
  WebkitMaskImage: '-webkit-radial-gradient(white, black)', // 1
  display: 'flex',
  alignItems: 'center',

  ...(background && {
    '::before': {
      borderRadius: theme.shape['borderRadius-l'],
      content: '""',
      position: 'absolute',
      zIndex: 0,
      inset: 0,

      ...(background === 'checkerboard' ? checkerboard(theme) : blurred(background)),
    },
  }),

  [theme.breakpoints.up('md')]: {
    minHeight: 350,
  },
}));

export const AssetPreview: FC<{ asset: Asset; videoFormat: VideoFormat }> = ({ asset, videoFormat }) => {
  const { aspectRatio, height } = VIDEO_FORMATS[videoFormat];

  return (
    <Container
      background="checkerboard"
      style={
        {
          '--aspect-ratio': String(aspectRatio),
          '--video-height': `${height}px`,
        } as CSSProperties
      }
    >
      {asset.type === 'image' ? <ImageAssetPreview asset={asset} /> : <VideoAssetPreview asset={asset} />}
    </Container>
  );
};
