import { useMemo } from 'react';
import { type MediaLibraryFilter, useMediaLibraryItems } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';

export const useHasAnyMediaLibraryItems = (filter?: MediaLibraryFilter) => {
  const { paginatedMediaLibraryItems, loading } = useMediaLibraryItems(filter);
  return useMemo(
    () => ({
      hasAnyMediaLibraryItems: paginatedMediaLibraryItems && paginatedMediaLibraryItems?.total > 0,
      loading,
    }),
    [paginatedMediaLibraryItems, loading]
  );
};
