import { useEffect, useMemo } from 'react';

import { MANAGER_API_URL } from '@cofenster/constants';
import { useAuthProviderByEmail } from '../api/hooks/authProvider/useAuthProviderByEmail';

const SSO_CALLBACK_URL = MANAGER_API_URL.replace('/graphql', '/sso');

export const useCheckSso = (email?: string, redirect?: string) => {
  const { authProvider, loading, getAuthProvider } = useAuthProviderByEmail(email);

  useEffect(() => {
    if (authProvider && email) {
      if (redirect) {
        location.href = `${SSO_CALLBACK_URL}?email=${encodeURIComponent(email)}&redirect=${encodeURIComponent(
          redirect
        )}`;
      } else {
        location.href = `${SSO_CALLBACK_URL}?email=${encodeURIComponent(email)}`;
      }
    }
  }, [authProvider, email, redirect]);

  return useMemo(() => ({ authProvider, loading, getAuthProvider }), [authProvider, loading, getAuthProvider]);
};
