import { type MultiSelect, type SortableContextProps, useSortableSensors } from '@cofenster/web-components';
import { DndContext, type DndContextProps, type Modifier, closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { type FC, type KeyboardEvent, type MouseEvent, useCallback, useMemo, useState } from 'react';

type OnDragStart = Required<DndContextProps>['onDragStart'];
type OnDragEnd = Required<DndContextProps>['onDragEnd'];

const RESTRICTIONS: Record<SortableContextProps['restrictTo'], Modifier[]> = {
  none: [],
  x: [restrictToHorizontalAxis],
  y: [restrictToVerticalAxis],
  parent: [restrictToParentElement],
};

type MultiSortableProps<T extends { id: string }, P extends {}> = {
  items: T[];
  multiSelect: MultiSelect<T>;
  autoSelectDraggedItem?: boolean;
  onSort: (items: T[]) => unknown;
  onTrackItemMove?: (item: T, from: number, to: number) => unknown;
  onItemDragStart?: (item: T, multiSelect: MultiSelect<T>) => unknown;
  onItemDragEnd?: (item: T, multiSelect: MultiSelect<T>) => unknown;
  Sortable: FC<
    {
      item: T;
      isSelected: boolean;
      selectionSize: number;
      onSelect: (event?: MouseEvent | KeyboardEvent) => void;
    } & NoInfer<P>
  >;
  sortableProps: P;
  restrictTo?: 'x' | 'y' | 'parent' | 'none';
};

export const MultiSortable = <T extends { id: string }, P extends {}>({
  items,
  multiSelect,
  autoSelectDraggedItem = false,
  onSort,
  onTrackItemMove,
  onItemDragStart,
  onItemDragEnd,
  Sortable,
  sortableProps,
  restrictTo = 'none',
}: MultiSortableProps<T, P>) => {
  const sensors = useSortableSensors();

  const [active, setActive] = useState<string | null>(null);

  const sortables = useMemo(() => {
    if (!active) return items;
    if (multiSelect.selectedItems.length < 2) return items;
    if (multiSelect.selectedItems.findIndex((item) => item.id === active) < 0) return items;
    return items.filter((item) => item.id === active || !multiSelect.selectedItems.includes(item));
  }, [items, active, multiSelect.selectedItems]);

  const onDragStart: OnDragStart = useCallback(
    (event) => {
      const activeId = event.active.id as string;
      const activeItem = items.find((item) => item.id === activeId);
      if (!activeItem) return;

      if (autoSelectDraggedItem && !multiSelect.selectedItems.includes(activeItem))
        multiSelect.setSelection([activeItem]);

      onItemDragStart?.(activeItem, multiSelect);
      setActive(activeId);
    },
    [items, multiSelect, onItemDragStart, autoSelectDraggedItem]
  );

  const onDragEnd: OnDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      setActive(null);

      if (!over) return;
      if (over.id === active.id) return;

      const activeItem = sortables.find((item) => item.id === active.id);
      if (!activeItem) return;

      onItemDragEnd?.(activeItem, multiSelect);

      const to = sortables.findIndex((item) => item.id === over.id);

      if (multiSelect.selectedItems.includes(activeItem)) {
        const unselectedItems = items.filter((item) => !multiSelect.selectedItems.includes(item));
        // `.toSorted(..)` is not fully supported everywhere
        // See: https://caniuse.com/mdn-javascript_builtins_array_tosorted
        // See: https://cofenster.sentry.io/issues/5858442334/events/55dc41b34893457285d6da06357d879f/
        const selectedItemsSortedByItems = multiSelect.selectedItems
          .slice()
          .sort((a, b) => items.indexOf(a) - items.indexOf(b));
        const newOrder = [...unselectedItems.slice(0, to), ...selectedItemsSortedByItems, ...unselectedItems.slice(to)];

        onSort(newOrder);

        if (onTrackItemMove) {
          selectedItemsSortedByItems.forEach((item, selectionIndex) => {
            const itemIndex = items.indexOf(item);
            onTrackItemMove(item, itemIndex, to + selectionIndex);
          });
        }
      } else {
        const from = items.indexOf(activeItem);
        onSort(arrayMove(items, from, to));
        onTrackItemMove?.(activeItem, from, to);
      }
    },
    [sortables, multiSelect, items, onSort, onTrackItemMove, onItemDragEnd]
  );

  const createOnSelect = useCallback(
    (item: T) => (event?: MouseEvent | KeyboardEvent) => {
      if (event?.shiftKey) {
        multiSelect.selectRangeTo(item);
        return;
      }

      if (event?.metaKey || event?.ctrlKey) {
        multiSelect.toggleSelection(item);
        return;
      }

      multiSelect.setSelection([item]);
    },
    [multiSelect]
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      collisionDetection={closestCenter}
      modifiers={RESTRICTIONS[restrictTo]}
    >
      <SortableContext items={sortables} strategy={rectSortingStrategy}>
        {sortables.map((item) => (
          <Sortable
            key={item.id}
            item={item}
            isSelected={multiSelect.selectedItems.includes(item)}
            selectionSize={multiSelect.selectedItems.length}
            onSelect={createOnSelect(item)}
            {...sortableProps}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
