import type { CSSProperties, FC, FunctionComponent, PropsWithChildren } from 'react';

import {
  DocumentTitleAnnouncer,
  Icon,
  ProjectHeader,
  type ProjectHeaderProps,
  styled,
} from '@cofenster/web-components';

import {
  PageNotification,
  type PageNotificationProps,
} from '@cofenster/web-components/src/components/layout/Page/PageNotification';
import { useAutomaticTracking } from '../../../hooks/useAutomaticTracking';
import { routes } from '../../../routes';

// 1. Occupy at least the full height of the parent, which is the root node from
//    React.
// 2. Set up a flex container so the main container can take up all the space
//    that is not occupied by the header.
const PageContainer = styled('div')(() => ({
  minHeight: '100%', // 1
  display: 'flex', // 2
  flexDirection: 'column', // 2
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.top,
    height: 'var(--project-header-height)',
    borderBottom: `1px solid ${theme.palette.brand.grey100}`,
  },
}));

// 1. Occupy all the available space that is not take by the header.
const Main = styled('main')(({ theme }) => ({
  flex: '1 1 100%', // 1
  backgroundColor: theme.palette.brand.linen,
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  maxWidth: 'var(--content-container-max-width, 960px)',
  margin: '0 auto',
  padding: theme.spacing(6, 2),
  flex: '1 1 100%',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0),
  },
}));

export type ProjectLayoutProps = PropsWithChildren<{
  documentTitle: string;
  header: ProjectHeaderProps;
  contentContainerMaxWidth?: CSSProperties['maxWidth'];
  ContentContainerComponent?: FunctionComponent;
  isProjectTemplate?: boolean;
}>;

// The project editor route is a little particular: we do use the router to
// dynamically store the current scene as the editor and player are being
// interacted with, but we do not want to re-announce the page and steal the
// focus every time the scene changes.
const IGNORED_PATHS_FROM_A11Y_ANNOUNCER = [routes.projectEditScene];

export const ProjectLayout: FC<ProjectLayoutProps> = ({
  children,
  header,
  documentTitle,
  contentContainerMaxWidth,
  ContentContainerComponent = ContentContainer,
  isProjectTemplate = false,
}) => {
  useAutomaticTracking();

  return (
    <>
      <DocumentTitleAnnouncer documentTitle={documentTitle} ignorePaths={IGNORED_PATHS_FROM_A11Y_ANNOUNCER} />
      <PageContainer
        style={
          {
            '--project-header-height': '88px',
            '--content-container-max-width': contentContainerMaxWidth,
          } as CSSProperties
        }
      >
        <PageNotification {...(isProjectTemplate ? PROJECT_TEMPLATE_NOTIFICATION : undefined)} />
        <Header>
          <ProjectHeader {...header} />
        </Header>
        <Main id="content">
          <ContentContainerComponent>{children}</ContentContainerComponent>
        </Main>
      </PageContainer>
    </>
  );
};

const StyledIcon = styled(Icon)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

export const PROJECT_TEMPLATE_NOTIFICATION: PageNotificationProps = {
  i18nKey: 'i18n.projectTemplate.pageNotification',
  i18nParams: { icon: <StyledIcon type="LayoutIcon" size="s" weight="bold" /> },
  'data-testid': 'project-template-edit-page-notification',
};
