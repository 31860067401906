import { Icon, Typography, styled, useSnackbars } from '@cofenster/web-components';
import { useCallback } from 'react';
import { useCreateMediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useCreateMediaLibraryItem';
import { RouterLink } from '../../components/button/RouterLink';
import { useWebManagerTracking } from '../useWebManagerTracking';

type AssetIds = { videoAssetId: string; imageAssetId?: never } | { videoAssetId?: never; imageAssetId: string };

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const useAddToMediaLibrary = (withSnackbar = false) => {
  const [createMediaLibraryItem, metadata] = useCreateMediaLibraryItem();
  const { trackEvent } = useWebManagerTracking();
  const { openSnackbar } = useSnackbars();

  const createAndTrack = useCallback(
    async (assetIds: AssetIds[]) => {
      const result = await createMediaLibraryItem(assetIds);
      if (result.data?.createMediaLibraryItems) {
        const items = result.data.createMediaLibraryItems;
        trackEvent({
          event: 'mediaLibraryItemsCreated',
          details: { mediaLibraryItemId: items.map((item) => item.id) },
        });

        if (withSnackbar) {
          openSnackbar({
            variant: 'info',
            children: (
              <FlexRow>
                <Icon type="MediaLibraryIcon" />
                <Typography>i18n.mediaLibrary.addedSnackbar</Typography>
                <RouterLink to="brandingMediaLibrary">
                  <Typography>i18n.mediaLibrary.addedSnackbar.view</Typography>
                </RouterLink>
              </FlexRow>
            ),
          });
        }
      }
    },
    [createMediaLibraryItem, trackEvent, openSnackbar, withSnackbar]
  );

  return [createAndTrack, metadata] as const;
};
