import { useEffect, useMemo } from 'react';
import {
  type MediaLibraryItemsQuery as ApolloMediaLibraryItemsQuery,
  type MediaLibraryItemsQueryResult,
  type MediaLibraryItemsQueryVariables,
  useMediaLibraryItemsQuery,
} from '../../generated';

export type MediaLibraryItemsQueryData = NonNullable<ApolloMediaLibraryItemsQuery>['mediaLibraryItems'];

export type MediaLibraryItem = NonNullable<
  NonNullable<NonNullable<MediaLibraryItemsQueryResult['data']>['mediaLibraryItems']>['items']
>[number];

export type MediaLibraryFilter = NonNullable<MediaLibraryItemsQueryVariables['filter']>;
export type MediaLibraryOrderBy = NonNullable<MediaLibraryFilter['orderBy']>;
export type MediaLibraryAssetType = NonNullable<MediaLibraryFilter['type']>;

const isAssetNotReady = (mediaLibraryItem: MediaLibraryItem) => {
  return (
    mediaLibraryItem.videoAsset?.status === 'Processing' ||
    mediaLibraryItem.videoAsset?.status === 'Waiting' ||
    mediaLibraryItem.imageAsset?.status === 'Waiting'
  );
};

export const useMediaLibraryItems = (
  filter?: MediaLibraryFilter,
  onCompleted?: (filter: MediaLibraryFilter | undefined, data: MediaLibraryItemsQueryData) => void
) => {
  const { loading, error, data, startPolling, stopPolling } = useMediaLibraryItemsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { filter },
    onCompleted: onCompleted
      ? (data) => {
          onCompleted(filter, data.mediaLibraryItems);
        }
      : undefined,
  });

  const shouldPoll = data?.mediaLibraryItems?.items.some(isAssetNotReady);

  useEffect(() => {
    if (shouldPoll) {
      startPolling(5000);
      return () => stopPolling();
    }
  }, [shouldPoll, startPolling, stopPolling]);

  return useMemo(
    () => ({ paginatedMediaLibraryItems: data?.mediaLibraryItems, loading, error }),
    [data?.mediaLibraryItems, loading, error]
  );
};
