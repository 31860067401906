import type { FC, ReactNode, RefObject } from 'react';

import { usePlayVideoOnHover } from '../../../hooks/media/usePlayVideoOnHover';

export const ThumbnailVideoInteraction: FC<{
  children: (props: {
    onInteractionEnter: VoidFunction;
    onInteractionLeave: VoidFunction;
    ref: RefObject<HTMLVideoElement>;
  }) => ReactNode;
}> = ({ children }) => {
  const { ref, onMouseEnter: onInteractionEnter, onMouseLeave: onInteractionLeave } = usePlayVideoOnHover();

  return children({ ref, onInteractionEnter, onInteractionLeave });
};
