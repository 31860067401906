import { LAYER_INDEXES, type VideoFormat } from '@cofenster/constants';
import { Button, type ButtonProps, Icon } from '@cofenster/web-components';
import { type FC, type MouseEventHandler, useCallback } from 'react';
import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';
import { useDialogs } from '../../../contexts/dialogs';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export const PickSceneAssetFromMediaLibraryButton: FC<
  {
    assetType: 'IMAGE' | 'VIDEO';
    sceneId: string;
    videoFormat: VideoFormat;
  } & Omit<ButtonProps, 'onClick' | 'children' | 'startIcon'>
> = ({ assetType, sceneId, videoFormat, ...rest }) => {
  const { openDialog } = useDialogs();

  const tracking = useWebManagerTracking();
  const track = useCallback(
    (mediaLibraryItemId: string) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId: sceneId,
          assetType: assetType,
          assetName: mediaLibraryItemId,
          uploadSource: 'media-library',
        },
      }),
    [assetType, sceneId, tracking]
  );

  const createSceneAsset = useCreateSceneAsset();
  const onMediaLibraryItemPicked = useCallback(
    async (item: MediaLibraryItem) => {
      const isVideo = !!item.videoAsset?.id;
      await createSceneAsset({
        assetType: isVideo ? 'Video' : 'Image',
        sceneId,
        assetId: item?.videoAsset?.id ?? (item?.imageAsset?.id as string),
        layerIndex: LAYER_INDEXES.main,
        startTime: 0,
      });
      track(item.id);
    },
    [createSceneAsset, sceneId, track]
  );

  const onClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog('PickMediaLibraryItemDialog', {
        assetType,
        onMediaLibraryItemPicked,
        videoFormat,
      });
    },
    [openDialog, assetType, onMediaLibraryItemPicked, videoFormat]
  );

  return (
    <Button variant="secondary" {...rest} onClick={onClick} startIcon={<Icon type="MediaLibraryIcon" size="s" />}>
      i18n.mediaLibrary.pickAssetButton
    </Button>
  );
};
