import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  GridContainer,
  GridItem,
  SubNavigation,
  SubNavigationItem,
  styled,
  useTabNavigation,
} from '@cofenster/web-components';
import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import type { SceneType } from '../../../api/hooks/scene/useCreateScene';
import { ContributionRecordings } from '../../../pages/ProjectContributionConfiguration/ProjectContributionConfigurationContent/ContributionRecordings';
import { BulkUpload } from '../../sceneAsset/BulkUpload';
import { SceneTypeCard } from './SceneTypeCard';
import { TwoStepMediaLibrary } from './TwoStepMediaLibrary';
import { useCreateScene } from './useCreateScene';

type AddSceneFormProps = {
  projectId: string;
  videoFormat: VideoFormat;
  isProjectTemplate: boolean;
  index: number;
  onCreate?: (sceneId: string) => unknown;
  onUploadStart?: (files: File[]) => void;
  onUploadEnd?: VoidFunction;
  bulkUploadInProgress: boolean;
  goToProjectContributionsPage?: VoidFunction;
  hasContributionRequestListAvailable: boolean;
  canUpdateProject: boolean;
};

const allTabs = ['New', 'Requests', 'MediaLibrary'];

const MediaLibraryActionsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
}));

export const AddSceneForm: FC<AddSceneFormProps> = ({
  projectId,
  index,
  onCreate,
  onUploadStart,
  onUploadEnd,
  goToProjectContributionsPage,
  bulkUploadInProgress,
  videoFormat,
  hasContributionRequestListAvailable,
  canUpdateProject,
  isProjectTemplate,
}) => {
  const tabs = useMemo(() => (isProjectTemplate ? ['New', 'MediaLibrary'] : allTabs), [isProjectTemplate]);
  const { currentTab, getTablistProps, getTabProps } = useTabNavigation(tabs, 'New');
  const [createScene, { loading }] = useCreateScene({ projectId, index, onCreate });
  const [creationType, setCreationType] = useState<SceneType | null>('image');

  useEffect(() => {
    if (!loading) setCreationType(null);
  }, [loading]);

  const create = useCallback(
    (sceneType: SceneType, assetId?: string) => {
      setCreationType(sceneType);
      return createScene(sceneType, assetId);
    },
    [createScene]
  );

  const onMediaLibraryItemPicked = useCallback(
    async (mediaLibraryItem: MediaLibraryItem | null) => {
      if (!mediaLibraryItem) return onUploadEnd?.();
      const isVideo = !!mediaLibraryItem.videoAsset?.id;
      return create(isVideo ? 'video' : 'image', mediaLibraryItem.videoAsset?.id ?? mediaLibraryItem.imageAsset?.id);
    },
    [create, onUploadEnd]
  );

  return (
    <GridContainer spacing={2}>
      {hasContributionRequestListAvailable && (
        <GridItem xs={12}>
          <SubNavigation {...getTablistProps()} fullWidth withBorderBottom>
            <SubNavigationItem {...getTabProps('New')} active={currentTab === 'New'} iconType="AddSceneIcon">
              i18n.projectEdit.sceneForm.tabs.new
            </SubNavigationItem>
            {!isProjectTemplate && (
              <SubNavigationItem
                {...getTabProps('Requests')}
                active={currentTab === 'Requests'}
                iconType="ShareFatIcon"
              >
                i18n.projectEdit.sceneForm.tabs.requests
              </SubNavigationItem>
            )}
            {__MEDIA_LIBRARY__ && (
              <SubNavigationItem
                {...getTabProps('MediaLibrary')}
                active={currentTab === 'MediaLibrary'}
                iconType="MediaLibraryIcon"
              >
                i18n.common.mediaLibrary
              </SubNavigationItem>
            )}
          </SubNavigation>
        </GridItem>
      )}
      {currentTab === 'New' && (
        <>
          {!bulkUploadInProgress && (
            <>
              <GridItem xs={12} sm={6} md={3}>
                <SceneTypeCard
                  onClick={() => create('video')}
                  loading={loading && creationType === 'video'}
                  disabled={loading && creationType !== 'video'}
                  data-testid="create-video-scene-button"
                  iconType="MovieIcon"
                  label="i18n.projectEdit.sceneForm.video"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <SceneTypeCard
                  onClick={() => create('image')}
                  loading={loading && creationType === 'image'}
                  disabled={loading && creationType !== 'image'}
                  data-testid="create-image-scene-button"
                  iconType="ImageIcon"
                  label="i18n.projectEdit.sceneForm.image"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <SceneTypeCard
                  onClick={() => create('color')}
                  loading={loading && creationType === 'color'}
                  disabled={loading && creationType !== 'color'}
                  data-testid="create-color-scene-button"
                  iconType="EyedropperIcon"
                  label="i18n.projectEdit.sceneForm.color"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <SceneTypeCard
                  onClick={() => create('screenRecording')}
                  loading={loading && creationType === 'screenRecording'}
                  disabled={loading && creationType !== 'screenRecording'}
                  data-testid="create-recording-scene-button"
                  iconType="ScreenShareIcon"
                  label="i18n.projectEdit.sceneForm.screenRecording"
                />
              </GridItem>
            </>
          )}
          <GridItem xs={12} height={216}>
            <BulkUpload
              projectId={projectId}
              index={index}
              onUploadStart={onUploadStart}
              onCreate={onCreate}
              onUploadEnd={onUploadEnd}
              disabled={loading}
            />
          </GridItem>
        </>
      )}
      {currentTab === 'Requests' && hasContributionRequestListAvailable && (
        <GridItem xs={12} mt={3}>
          <ContributionRecordings
            projectId={projectId}
            onSceneCreated={onCreate}
            videoFormat={videoFormat}
            newSceneIndex={index}
            goToProjectContributionsPage={goToProjectContributionsPage}
            canUpdateProject={canUpdateProject}
            withBulkSelection={false}
          />
        </GridItem>
      )}
      {currentTab === 'MediaLibrary' && __MEDIA_LIBRARY__ && (
        <GridItem xs={12}>
          <TwoStepMediaLibrary
            onMediaLibraryItemPicked={onMediaLibraryItemPicked}
            videoFormat={videoFormat}
            ActionsComponent={MediaLibraryActionsContainer}
            confirmationText="i18n.common.create"
          />
        </GridItem>
      )}
    </GridContainer>
  );
};
