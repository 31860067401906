import type { FC, ReactNode } from 'react';

import { EmptyState, Icon } from '@cofenster/web-components';

export const MediaLibraryEmptyState: FC<{
  variant?: 'solid' | 'transparent';
  cta?: ReactNode;
}> = ({ variant = 'transparent', cta }) => {
  if (variant === 'transparent') {
    return (
      <EmptyState
        backgroundColor="transparent"
        icon={<Icon type="MediaLibraryIcon" size="l" background={{ size: 'xxl', color: 'linen50' }} />}
        title="i18n.mediaLibrary.emptyState.title"
        description="i18n.mediaLibrary.emptyState.description"
        cta={cta}
      />
    );
  }

  return (
    <EmptyState
      iconType="MediaLibraryIcon"
      title="i18n.mediaLibrary.emptyState.title"
      description="i18n.mediaLibrary.emptyState.description"
      cta={cta}
    />
  );
};
