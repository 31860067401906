import { Dialog, IconButton, styled } from '@cofenster/web-components';
import { type FC, useState } from 'react';
import { PickDestination } from './PickDestination';
import { PickExistingProject } from './PickExistingProject';

type MoveScenesToProjectDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  sceneIds: string[];
  currentProjectId: string;
  onMoveToNewProject: (data: { sceneIds: string[]; keepSourceScenes: boolean }) => unknown;
  onMoveToExistingProject: (data: {
    sceneIds: string[];
    targetProjectId: string;
    keepSourceScenes: boolean;
  }) => unknown;
};

// 1. Prevent the horizontal scrollbar from appearing when the dialog width changes.
const AnimatedWidthDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': { transition: 'width 0.4s' },
  '.MuiDialogContent-root': {
    overflowX: 'hidden', // 1
  },
}));

export const MoveScenesToProjectDialog: FC<MoveScenesToProjectDialogProps> = ({
  isOpen,
  closeDialog,
  sceneIds,
  currentProjectId,
  onMoveToNewProject,
  onMoveToExistingProject,
}) => {
  const [state, setState] = useState<
    | { step: 'PICK_DESTINATION' }
    | {
        step: 'PICK_EXISTING_PROJECT';
        keepSourceScenes: boolean;
      }
  >({ step: 'PICK_DESTINATION' });

  return (
    <AnimatedWidthDialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="move-scenes-to-project-dialog"
      title="i18n.dialogs.MoveScenesToProjectDialog.title"
      size={state.step === 'PICK_DESTINATION' ? 's' : 'l'}
      leftIcon={
        state.step === 'PICK_EXISTING_PROJECT' && (
          <IconButton
            icon="ArrowLeftIcon"
            label="i18n.global.back"
            onClick={() => setState({ step: 'PICK_DESTINATION' })}
          />
        )
      }
      scroll="paper"
    >
      {state.step === 'PICK_DESTINATION' && (
        <PickDestination
          sceneCount={sceneIds.length}
          onCancel={closeDialog}
          onPick={({ destination, keepSourceScenes }) => {
            if (destination === 'NEW_PROJECT') {
              onMoveToNewProject({ sceneIds, keepSourceScenes });
            } else {
              setState({ step: 'PICK_EXISTING_PROJECT', keepSourceScenes });
            }
          }}
        />
      )}
      {state.step === 'PICK_EXISTING_PROJECT' && (
        <PickExistingProject
          currentProjectId={currentProjectId}
          onCancel={() => setState({ step: 'PICK_DESTINATION' })}
          onPick={({ targetProjectId }) =>
            onMoveToExistingProject({
              sceneIds,
              targetProjectId,
              keepSourceScenes: state.keepSourceScenes,
            })
          }
        />
      )}
    </AnimatedWidthDialog>
  );
};
