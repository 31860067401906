import { type FC, useCallback, useMemo, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { AssetPreview, Button, Dialog, DialogActions, DialogContent, Icon, styled } from '@cofenster/web-components';

import type { StockImage } from '../../../api/hooks/stockFootage/useSearchStockImages';
import type { StockVideo } from '../../../api/hooks/stockFootage/useSearchStockVideos';

import { Attribution } from './Attribution';
import { SearchContext } from './SearchContext';
import { SearchState } from './SearchState';
import { useSearch } from './useSearch';

export type StockLibraryDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  type: 'image' | 'video';
  pick: (id: string) => void;
  videoFormat: VideoFormat;
};

const StyledDialog = styled(Dialog)(() => ({
  '& > .MuiDialog-container > .MuiPaper-root': {
    maxHeight: '75vh',
  },
}));

const typeToTranslation = {
  image: 'i18n.dialogs.StockLibraryDialog.save.image',
  video: 'i18n.dialogs.StockLibraryDialog.save.video',
};

export const StockLibraryDialog: FC<StockLibraryDialogProps> = ({ isOpen, closeDialog, type, pick, videoFormat }) => {
  const [selectedAsset, setSelectedAsset] = useState<StockImage | StockVideo | null>(null);
  const [search, metadata] = useSearch(type, videoFormat);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = useCallback(async () => {
    if (selectedAsset) {
      setIsSubmitting(true);
      await pick(selectedAsset.id);
      setIsSubmitting(false);
      closeDialog();
    }
  }, [closeDialog, pick, selectedAsset]);

  const context = useMemo(
    () => ({ searchTerm, setSearchTerm, page, setPage, search, metadata, type, videoFormat }),
    [metadata, page, search, searchTerm, type, videoFormat]
  );

  const mapAsset = useCallback((stockAsset: StockVideo | StockImage) => {
    if (stockAsset.__typename === 'StockVideo') {
      return {
        type: 'video' as const,
        videoUrl: stockAsset.videoUrl,
        posterUrl: stockAsset.posterUrl,
      };
    }

    if (stockAsset.__typename === 'StockImage') {
      return {
        type: 'image' as const,
        imageUrl: stockAsset.imageUrl,
        alt: stockAsset.alt,
      };
    }

    throw new Error('Unknown __typename for stock asset');
  }, []);

  return (
    <StyledDialog
      open={isOpen}
      onClose={closeDialog}
      title="i18n.dialogs.StockLibraryDialog.title"
      size="l"
      data-testid="stock-library-dialog"
    >
      <SearchContext.Provider value={context}>
        <DialogContent>
          {selectedAsset ? (
            <AssetPreview asset={mapAsset(selectedAsset)} videoFormat={context.videoFormat} />
          ) : (
            <SearchState setSelectedAsset={setSelectedAsset} />
          )}
        </DialogContent>

        {selectedAsset ? (
          <DialogActions>
            {selectedAsset?.attributor && <Attribution asset={selectedAsset} />}
            <Button
              variant="tertiary"
              onClick={() => setSelectedAsset(null)}
              style={{ marginLeft: 'auto' }}
              disabled={isSubmitting}
              data-testid="preview-back-button"
            >
              i18n.global.back
            </Button>
            <Button
              onClick={handleSave}
              loading={isSubmitting}
              startIcon={<Icon type="PlusCircleIcon" size="s" />}
              data-testid="preview-save-button"
            >
              {typeToTranslation[type]}
            </Button>
          </DialogActions>
        ) : null}
      </SearchContext.Provider>
    </StyledDialog>
  );
};
