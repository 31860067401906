import { useGoto } from '@cofenster/web-components';
import { useCallback } from 'react';
import type { ProjectTemplate } from '../../../api/hooks/projectTemplate/useProjectTemplates';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { routes } from '../../../routes';

export const useOnEditProjectTemplate = (projectTemplate: ProjectTemplate) => {
  const confirm = useConfirmDialog({
    title: 'i18n.projectTemplates.editTemplateConfirmDialog.title',
    content: 'i18n.projectTemplates.editTemplateConfirmDialog.content',
    confirm: 'i18n.projectTemplates.editTemplateConfirmDialog.confirm',
    variant: 'primary',
  });
  const goto = useGoto();

  return useCallback(async () => {
    if (await confirm()) {
      goto(routes.projectEdit, { projectId: projectTemplate.project.id });
    }
  }, [goto, projectTemplate.project.id, confirm]);
};
