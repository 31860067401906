import { useMemo } from 'react';

import { PopoverMenuItem, useFeatureFlags } from '@cofenster/web-components';

import type { VideoSceneAsset } from '../../../../../api/hooks/scene/useScenes';
import { useProject } from '../../../../../contexts/project/useProject';
import type { Scene } from '../../../../../contexts/scenes/useScenes';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import {
  AddToMediaLibraryPopoverMenuItem,
  AddTransitionPopoverMenuItem,
  CopyPopoverMenuItem,
  DeletePopoverMenuItem,
  DuplicatePopoverMenuItem,
  InsertScenePopoverMenuItem,
  InspectPopoverMenuItem,
  PastePopoverMenuItem,
  ToggleSceneFromVideo,
  useCanInspectAsset,
} from '../components/ScenePopoverActions';

import { useAccountPermissionStatus } from '../../../../../contexts/user/AccountPermissionRestriction';
import { DeleteSceneAssetPopoverMenuItem } from '../components/DeleteSceneAssetPopoverMenuItem';
import { useAddPopoverDividers } from './useAddPopoverDividers';
import { useDownloadSceneAssetsWithTracking } from './useDownloadSceneAssetsWithTracking';
import { useOnMoveToProject } from './useOnMoveToProject';

type Props = {
  scene: Scene;
  videoSceneAsset: VideoSceneAsset | undefined;
};

export const useSceneVideoActionsPopoverContent = ({ scene, videoSceneAsset }: Props) => {
  const { project } = useProject();
  const isAllowedToUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const isAllowedToAddToMediaLibrary = useAccountPermissionStatus({ has: 'MediaLibraryCreate' }) === 'ALLOWED';
  const addToMediaLibraryEnabled = useFeatureFlags().hasFeature('ADD_TO_MEDIA_LIBRARY');
  const addPopoverDividers = useAddPopoverDividers();
  const canInspectAsset = useCanInspectAsset(videoSceneAsset?.asset?.videoAsset?.id);
  const onMoveToProject = useOnMoveToProject(project?.id ?? '', scene.id);

  const { download, hasAnythingToDownload } = useDownloadSceneAssetsWithTracking(scene);

  return useMemo(() => {
    const videoAsset = videoSceneAsset?.asset?.videoAsset;
    const videoUrl = videoAsset?.videoUrl;
    const hasError = videoAsset?.status === 'Error';
    const isProjectArchived = Boolean(project?.archivedAt);
    const canUpdateProject = !isProjectArchived && isAllowedToUpdateProject;
    const canAddToMediaLibrary = __MEDIA_LIBRARY__ && isAllowedToAddToMediaLibrary && !!videoAsset;

    const add = [
      canUpdateProject && (
        <InsertScenePopoverMenuItem
          key="insert-scene"
          hasContributionRequestListAvailable={project?.hasContributionRequestListAvailable ?? false}
          sceneId={scene.id}
        />
      ),
    ];

    const top = [
      canUpdateProject && <AddTransitionPopoverMenuItem scene={scene} key="add-transition" />,
      canUpdateProject && <ToggleSceneFromVideo scene={scene} key="toggle-scene-from-video" />,
    ].filter(Boolean);

    const middle = [
      <CopyPopoverMenuItem key="copy-scene" scene={scene} />,
      canUpdateProject && <PastePopoverMenuItem key="paste-scene" projectId={project?.id} scene={scene} />,
      !hasError && canUpdateProject && <DuplicatePopoverMenuItem key="duplicate-scene" scene={scene} />,
      isAllowedToUpdateProject && (
        <PopoverMenuItem
          key="scene-move-to-project"
          icon="FolderPlusIcon"
          data-testid="move-scenes-to-project"
          onClick={onMoveToProject}
        >
          i18n.projectEditor.sceneAssetThumbs.popover.actions.moveToProject
        </PopoverMenuItem>
      ),
      canUpdateProject && <DeletePopoverMenuItem key="delete-scene" scene={scene} />,
    ].filter(Boolean);

    const bottom = [
      canAddToMediaLibrary && (
        <AddToMediaLibraryPopoverMenuItem
          key="add-to-media-library"
          sceneId={scene.id}
          disabled={!addToMediaLibraryEnabled}
        />
      ),
      hasAnythingToDownload && (
        <PopoverMenuItem key="download-asset" data-testid="scene-download-asset" icon="DownloadIcon" onClick={download}>
          i18n.projectEditor.sceneAssetThumbs.popover.actions.download.video
        </PopoverMenuItem>
      ),
      videoUrl && canUpdateProject && <DeleteSceneAssetPopoverMenuItem key="delete-asset" scene={scene} />,
    ].filter(Boolean);

    const inspect = [
      canInspectAsset && <InspectPopoverMenuItem key="inspect-asset" videoAssetId={videoAsset?.id} />,
    ].filter(Boolean);

    return addPopoverDividers([add, top, middle, bottom, inspect]);
  }, [
    project?.id,
    videoSceneAsset?.asset?.videoAsset,
    project?.archivedAt,
    addToMediaLibraryEnabled,
    isAllowedToUpdateProject,
    isAllowedToAddToMediaLibrary,
    scene,
    download,
    canInspectAsset,
    addPopoverDividers,
    project?.hasContributionRequestListAvailable,
    onMoveToProject,
    hasAnythingToDownload,
  ]);
};
