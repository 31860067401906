import { type FC, Fragment, useCallback, useMemo, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { AssetPreview, Button, Icon } from '@cofenster/web-components';

import type { MediaLibraryItem } from '../../../api/hooks/mediaLibraryItem/useMediaLibraryItems';
import { MediaLibraryEmptyState } from '../../../pages/MediaLibrary/MediaLibraryEmptyState';
import { MediaLibraryItemsGridView } from '../../../pages/MediaLibrary/MediaLibraryItemsGridView';
import { MediaLibrarySearchAndPagination } from '../../../pages/MediaLibrary/MediaLibrarySearchAndPagination';
import { MediaLibrarySearchWithPaginationContext } from '../../../pages/MediaLibrary/MediaLibrarySearchWithPaginationContext';
import { RouterButton } from '../../button/RouterButton';

export const TwoStepMediaLibrary: FC<{
  onMediaLibraryItemPicked: (mediaLibraryItem: MediaLibraryItem | null) => Promise<unknown>;
  videoFormat: VideoFormat;
  assetType?: 'IMAGE' | 'VIDEO';
  confirmationText?: string;
  ContentComponent?: FC;
  ActionsComponent?: FC;
}> = ({
  onMediaLibraryItemPicked,
  videoFormat,
  assetType,
  confirmationText,
  ContentComponent = Fragment,
  ActionsComponent = Fragment,
}) => {
  const [selectedItem, setSelectedItem] = useState<MediaLibraryItem | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = useCallback(async () => {
    if (!selectedItem) return;
    try {
      setIsSubmitting(true);
      await onMediaLibraryItemPicked(selectedItem);
    } finally {
      setIsSubmitting(false);
    }
  }, [onMediaLibraryItemPicked, selectedItem]);

  const MediaLibraryResults = useMemo(
    () =>
      ({ mediaLibraryItems }: { mediaLibraryItems: MediaLibraryItem[] }) => (
        <MediaLibraryItemsGridView mediaLibraryItems={mediaLibraryItems} onItemClick={setSelectedItem} />
      ),
    []
  );

  const mapAsset = useCallback((mediaLibraryItem: MediaLibraryItem) => {
    const { imageAsset, videoAsset } = mediaLibraryItem;
    if (videoAsset) {
      return {
        type: 'video' as const,
        videoUrl: videoAsset.videoUrl as string,
        posterUrl: videoAsset.thumbnailUrl as string,
      };
    }

    if (imageAsset) {
      return {
        type: 'image' as const,
        imageUrl: imageAsset.imageUrl as string,
      };
    }

    throw new Error(`There is no imageAsset and videoAsset for this mediaLibraryItem (${mediaLibraryItem.id})}`);
  }, []);

  return (
    <>
      <ContentComponent>
        <MediaLibrarySearchWithPaginationContext
          itemsPerPage={9}
          defaultValues={{
            type: assetType,
          }}
        >
          {selectedItem ? (
            <AssetPreview asset={mapAsset(selectedItem)} videoFormat={videoFormat} />
          ) : (
            <MediaLibrarySearchAndPagination
              Results={MediaLibraryResults}
              hideTypeFilter={!!assetType}
              emptyState={
                <MediaLibraryEmptyState
                  cta={
                    <RouterButton to="brandingMediaLibrary" onClick={() => onMediaLibraryItemPicked(null)}>
                      i18n.mediaLibrary.emptyState.cta
                    </RouterButton>
                  }
                  variant="transparent"
                />
              }
            />
          )}
        </MediaLibrarySearchWithPaginationContext>
      </ContentComponent>
      {selectedItem ? (
        <ActionsComponent>
          <Button
            variant="tertiary"
            onClick={() => setSelectedItem(null)}
            style={{ marginLeft: 'auto' }}
            disabled={isSubmitting}
            data-testid="preview-back-button"
          >
            i18n.global.back
          </Button>
          <Button
            onClick={submit}
            loading={isSubmitting}
            startIcon={<Icon type="PlusCircleIcon" size="s" />}
            data-testid="preview-save-button"
          >
            {confirmationText
              ? confirmationText
              : selectedItem.videoAsset
                ? 'i18n.dialogs.StockLibraryDialog.save.video'
                : 'i18n.dialogs.StockLibraryDialog.save.image'}
          </Button>
        </ActionsComponent>
      ) : null}
    </>
  );
};
